'use client'

import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from '@bpinternal/ui-kit-next'
import type { NavItem } from './AppSidebar'
import { Link } from '@tanstack/react-router'
import type { ReactNode } from 'react'

export function NavGroup({ items, title, children }: { title?: ReactNode; items: NavItem[]; children?: ReactNode }) {
  return (
    <SidebarGroup>
      {title && <SidebarGroupLabel>{title}</SidebarGroupLabel>}
      <SidebarMenu>
        {children}
        {items.map(({ title, icon: Icon, isActive, linkOptions, content }) => {
          return (
            <SidebarMenuItem key={title}>
              <SidebarMenuButton asChild tooltip={title} isActive={isActive}>
                <Link {...linkOptions}>
                  {Icon && <Icon />}
                  <span>{title}</span>
                  {content}
                </Link>
              </SidebarMenuButton>
            </SidebarMenuItem>
          )
        })}
      </SidebarMenu>
    </SidebarGroup>
  )
}
