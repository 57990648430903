/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as WelcomeImport } from './routes/welcome'
import { Route as ForbiddenImport } from './routes/forbidden'
import { Route as SplatImport } from './routes/$'
import { Route as IndexImport } from './routes/index'
import { Route as WorkspacesIndexImport } from './routes/workspaces/index'
import { Route as HubIndexImport } from './routes/hub/index'
import { Route as WorkspacesWorkspaceIdImport } from './routes/workspaces/$workspaceId'
import { Route as ProfileSettingsImport } from './routes/profile/settings'
import { Route as ProfileWorkspaceIdImport } from './routes/profile/$workspaceId'
import { Route as OauthInterstitialImport } from './routes/oauth/interstitial'
import { Route as WorkspacesWorkspaceIdIndexImport } from './routes/workspaces/$workspaceId/index'
import { Route as HubIntegrationsIndexImport } from './routes/hub/integrations/index'
import { Route as WorkspacesWorkspaceIdUsageImport } from './routes/workspaces/$workspaceId/usage'
import { Route as WorkspacesWorkspaceIdSettingsImport } from './routes/workspaces/$workspaceId/settings'
import { Route as WorkspacesWorkspaceIdHomeImport } from './routes/workspaces/$workspaceId/home'
import { Route as WorkspacesWorkspaceIdChatbotsImport } from './routes/workspaces/$workspaceId/chatbots_'
import { Route as WorkspacesWorkspaceIdBotsImport } from './routes/workspaces/$workspaceId/bots_'
import { Route as WorkspacesWorkspaceIdBillingImport } from './routes/workspaces/$workspaceId/billing'
import { Route as WorkspacesWorkspaceIdSplatImport } from './routes/workspaces/$workspaceId/$'
import { Route as HubIntegrationsIntegrationIdImport } from './routes/hub/integrations/$integrationId'
import { Route as WorkspacesWorkspaceIdSettingsIndexImport } from './routes/workspaces/$workspaceId/settings/index'
import { Route as WorkspacesWorkspaceIdIntegrationsIndexImport } from './routes/workspaces/$workspaceId/integrations/index'
import { Route as WorkspacesWorkspaceIdBillingIndexImport } from './routes/workspaces/$workspaceId/billing/index'
import { Route as WorkspacesWorkspaceIdSettingsMembersImport } from './routes/workspaces/$workspaceId/settings/members'
import { Route as WorkspacesWorkspaceIdSettingsDetailsImport } from './routes/workspaces/$workspaceId/settings/details'
import { Route as WorkspacesWorkspaceIdSettingsAuditsImport } from './routes/workspaces/$workspaceId/settings/audits'
import { Route as WorkspacesWorkspaceIdSettingsSplatImport } from './routes/workspaces/$workspaceId/settings/$'
import { Route as WorkspacesWorkspaceIdProfileSettingsImport } from './routes/workspaces/$workspaceId/profile/settings'
import { Route as WorkspacesWorkspaceIdIntegrationsIntegrationIdImport } from './routes/workspaces/$workspaceId/integrations/$integrationId'
import { Route as WorkspacesWorkspaceIdChatbotsBotIdImport } from './routes/workspaces/$workspaceId/chatbots/$botId'
import { Route as WorkspacesWorkspaceIdBotsBotIdImport } from './routes/workspaces/$workspaceId/bots/$botId'
import { Route as WorkspacesWorkspaceIdBillingSummaryImport } from './routes/workspaces/$workspaceId/billing/summary'
import { Route as WorkspacesWorkspaceIdBillingPlansImport } from './routes/workspaces/$workspaceId/billing/plans'
import { Route as WorkspacesWorkspaceIdBillingAddOnsImport } from './routes/workspaces/$workspaceId/billing/add-ons'
import { Route as WorkspacesWorkspaceIdIntegrationsIntegrationIdIndexImport } from './routes/workspaces/$workspaceId/integrations/$integrationId/index'
import { Route as WorkspacesWorkspaceIdBotsBotIdIndexImport } from './routes/workspaces/$workspaceId/bots/$botId/index'
import { Route as WorkspacesWorkspaceIdIntegrationsIntegrationIdVersionsImport } from './routes/workspaces/$workspaceId/integrations/$integrationId/versions'
import { Route as WorkspacesWorkspaceIdIntegrationsIntegrationIdSettingsImport } from './routes/workspaces/$workspaceId/integrations/$integrationId/settings'
import { Route as WorkspacesWorkspaceIdIntegrationsIntegrationIdLogsImport } from './routes/workspaces/$workspaceId/integrations/$integrationId/logs'
import { Route as WorkspacesWorkspaceIdIntegrationsIntegrationIdSplatImport } from './routes/workspaces/$workspaceId/integrations/$integrationId/$'
import { Route as WorkspacesWorkspaceIdChatbotsBotIdSplatImport } from './routes/workspaces/$workspaceId/chatbots/$botId/$'
import { Route as WorkspacesWorkspaceIdBotsBotIdWebchatImport } from './routes/workspaces/$workspaceId/bots/$botId/webchat'
import { Route as WorkspacesWorkspaceIdBotsBotIdUsersImport } from './routes/workspaces/$workspaceId/bots/$botId/users'
import { Route as WorkspacesWorkspaceIdBotsBotIdOverviewImport } from './routes/workspaces/$workspaceId/bots/$botId/overview'
import { Route as WorkspacesWorkspaceIdBotsBotIdLogsImport } from './routes/workspaces/$workspaceId/bots/$botId/logs'
import { Route as WorkspacesWorkspaceIdBotsBotIdHitlImport } from './routes/workspaces/$workspaceId/bots/$botId/hitl'
import { Route as WorkspacesWorkspaceIdBotsBotIdFilesImport } from './routes/workspaces/$workspaceId/bots/$botId/files'
import { Route as WorkspacesWorkspaceIdBotsBotIdEventsImport } from './routes/workspaces/$workspaceId/bots/$botId/events'
import { Route as WorkspacesWorkspaceIdBotsBotIdConfigurationVariablesImport } from './routes/workspaces/$workspaceId/bots/$botId/configuration-variables'
import { Route as WorkspacesWorkspaceIdBotsBotIdAnalyticsImport } from './routes/workspaces/$workspaceId/bots/$botId/analytics'
import { Route as WorkspacesWorkspaceIdBotsBotIdSplatImport } from './routes/workspaces/$workspaceId/bots/$botId/$'
import { Route as WorkspacesWorkspaceIdBotsBotIdWebchatIndexImport } from './routes/workspaces/$workspaceId/bots/$botId/webchat/index'
import { Route as WorkspacesWorkspaceIdBotsBotIdTablesIndexImport } from './routes/workspaces/$workspaceId/bots/$botId/tables/index'
import { Route as WorkspacesWorkspaceIdBotsBotIdIssuesIndexImport } from './routes/workspaces/$workspaceId/bots/$botId/issues/index'
import { Route as WorkspacesWorkspaceIdBotsBotIdConversationsIndexImport } from './routes/workspaces/$workspaceId/bots/$botId/conversations/index'
import { Route as WorkspacesWorkspaceIdBotsBotIdTablesTableIdImport } from './routes/workspaces/$workspaceId/bots/$botId/tables/$tableId'
import { Route as WorkspacesWorkspaceIdBotsBotIdIssuesIssueIdImport } from './routes/workspaces/$workspaceId/bots/$botId/issues/$issueId'
import { Route as WorkspacesWorkspaceIdBotsBotIdConversationsConversationIdImport } from './routes/workspaces/$workspaceId/bots/$botId/conversations/$conversationId'
import { Route as WorkspacesWorkspaceIdBotsBotIdWebchatV2ThemeImport } from './routes/workspaces/$workspaceId/bots/$botId/webchat/v2/theme'
import { Route as WorkspacesWorkspaceIdBotsBotIdWebchatV2ShareImport } from './routes/workspaces/$workspaceId/bots/$botId/webchat/v2/share'
import { Route as WorkspacesWorkspaceIdBotsBotIdWebchatV2GeneralImport } from './routes/workspaces/$workspaceId/bots/$botId/webchat/v2/general'
import { Route as WorkspacesWorkspaceIdBotsBotIdWebchatV2AdvancedSettingsImport } from './routes/workspaces/$workspaceId/bots/$botId/webchat/v2/advanced-settings'
import { Route as WorkspacesWorkspaceIdBotsBotIdConversationsMessageMessageIdImport } from './routes/workspaces/$workspaceId/bots/$botId/conversations/message/$messageId'

// Create/Update Routes

const WelcomeRoute = WelcomeImport.update({
  id: '/welcome',
  path: '/welcome',
  getParentRoute: () => rootRoute,
} as any)

const ForbiddenRoute = ForbiddenImport.update({
  id: '/forbidden',
  path: '/forbidden',
  getParentRoute: () => rootRoute,
} as any)

const SplatRoute = SplatImport.update({
  id: '/$',
  path: '/$',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const WorkspacesIndexRoute = WorkspacesIndexImport.update({
  id: '/workspaces/',
  path: '/workspaces/',
  getParentRoute: () => rootRoute,
} as any)

const HubIndexRoute = HubIndexImport.update({
  id: '/hub/',
  path: '/hub/',
  getParentRoute: () => rootRoute,
} as any)

const WorkspacesWorkspaceIdRoute = WorkspacesWorkspaceIdImport.update({
  id: '/workspaces/$workspaceId',
  path: '/workspaces/$workspaceId',
  getParentRoute: () => rootRoute,
} as any)

const ProfileSettingsRoute = ProfileSettingsImport.update({
  id: '/profile/settings',
  path: '/profile/settings',
  getParentRoute: () => rootRoute,
} as any)

const ProfileWorkspaceIdRoute = ProfileWorkspaceIdImport.update({
  id: '/profile/$workspaceId',
  path: '/profile/$workspaceId',
  getParentRoute: () => rootRoute,
} as any)

const OauthInterstitialRoute = OauthInterstitialImport.update({
  id: '/oauth/interstitial',
  path: '/oauth/interstitial',
  getParentRoute: () => rootRoute,
} as any)

const WorkspacesWorkspaceIdIndexRoute = WorkspacesWorkspaceIdIndexImport.update(
  {
    id: '/',
    path: '/',
    getParentRoute: () => WorkspacesWorkspaceIdRoute,
  } as any,
)

const HubIntegrationsIndexRoute = HubIntegrationsIndexImport.update({
  id: '/hub/integrations/',
  path: '/hub/integrations/',
  getParentRoute: () => rootRoute,
} as any)

const WorkspacesWorkspaceIdUsageRoute = WorkspacesWorkspaceIdUsageImport.update(
  {
    id: '/usage',
    path: '/usage',
    getParentRoute: () => WorkspacesWorkspaceIdRoute,
  } as any,
)

const WorkspacesWorkspaceIdSettingsRoute =
  WorkspacesWorkspaceIdSettingsImport.update({
    id: '/settings',
    path: '/settings',
    getParentRoute: () => WorkspacesWorkspaceIdRoute,
  } as any)

const WorkspacesWorkspaceIdHomeRoute = WorkspacesWorkspaceIdHomeImport.update({
  id: '/home',
  path: '/home',
  getParentRoute: () => WorkspacesWorkspaceIdRoute,
} as any)

const WorkspacesWorkspaceIdChatbotsRoute =
  WorkspacesWorkspaceIdChatbotsImport.update({
    id: '/chatbots_',
    path: '/chatbots',
    getParentRoute: () => WorkspacesWorkspaceIdRoute,
  } as any)

const WorkspacesWorkspaceIdBotsRoute = WorkspacesWorkspaceIdBotsImport.update({
  id: '/bots_',
  path: '/bots',
  getParentRoute: () => WorkspacesWorkspaceIdRoute,
} as any)

const WorkspacesWorkspaceIdBillingRoute =
  WorkspacesWorkspaceIdBillingImport.update({
    id: '/billing',
    path: '/billing',
    getParentRoute: () => WorkspacesWorkspaceIdRoute,
  } as any)

const WorkspacesWorkspaceIdSplatRoute = WorkspacesWorkspaceIdSplatImport.update(
  {
    id: '/$',
    path: '/$',
    getParentRoute: () => WorkspacesWorkspaceIdRoute,
  } as any,
)

const HubIntegrationsIntegrationIdRoute =
  HubIntegrationsIntegrationIdImport.update({
    id: '/hub/integrations/$integrationId',
    path: '/hub/integrations/$integrationId',
    getParentRoute: () => rootRoute,
  } as any)

const WorkspacesWorkspaceIdSettingsIndexRoute =
  WorkspacesWorkspaceIdSettingsIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => WorkspacesWorkspaceIdSettingsRoute,
  } as any)

const WorkspacesWorkspaceIdIntegrationsIndexRoute =
  WorkspacesWorkspaceIdIntegrationsIndexImport.update({
    id: '/integrations/',
    path: '/integrations/',
    getParentRoute: () => WorkspacesWorkspaceIdRoute,
  } as any)

const WorkspacesWorkspaceIdBillingIndexRoute =
  WorkspacesWorkspaceIdBillingIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => WorkspacesWorkspaceIdBillingRoute,
  } as any)

const WorkspacesWorkspaceIdSettingsMembersRoute =
  WorkspacesWorkspaceIdSettingsMembersImport.update({
    id: '/members',
    path: '/members',
    getParentRoute: () => WorkspacesWorkspaceIdSettingsRoute,
  } as any)

const WorkspacesWorkspaceIdSettingsDetailsRoute =
  WorkspacesWorkspaceIdSettingsDetailsImport.update({
    id: '/details',
    path: '/details',
    getParentRoute: () => WorkspacesWorkspaceIdSettingsRoute,
  } as any)

const WorkspacesWorkspaceIdSettingsAuditsRoute =
  WorkspacesWorkspaceIdSettingsAuditsImport.update({
    id: '/audits',
    path: '/audits',
    getParentRoute: () => WorkspacesWorkspaceIdSettingsRoute,
  } as any)

const WorkspacesWorkspaceIdSettingsSplatRoute =
  WorkspacesWorkspaceIdSettingsSplatImport.update({
    id: '/$',
    path: '/$',
    getParentRoute: () => WorkspacesWorkspaceIdSettingsRoute,
  } as any)

const WorkspacesWorkspaceIdProfileSettingsRoute =
  WorkspacesWorkspaceIdProfileSettingsImport.update({
    id: '/profile/settings',
    path: '/profile/settings',
    getParentRoute: () => WorkspacesWorkspaceIdRoute,
  } as any)

const WorkspacesWorkspaceIdIntegrationsIntegrationIdRoute =
  WorkspacesWorkspaceIdIntegrationsIntegrationIdImport.update({
    id: '/integrations/$integrationId',
    path: '/integrations/$integrationId',
    getParentRoute: () => WorkspacesWorkspaceIdRoute,
  } as any)

const WorkspacesWorkspaceIdChatbotsBotIdRoute =
  WorkspacesWorkspaceIdChatbotsBotIdImport.update({
    id: '/chatbots/$botId',
    path: '/chatbots/$botId',
    getParentRoute: () => WorkspacesWorkspaceIdRoute,
  } as any)

const WorkspacesWorkspaceIdBotsBotIdRoute =
  WorkspacesWorkspaceIdBotsBotIdImport.update({
    id: '/bots/$botId',
    path: '/bots/$botId',
    getParentRoute: () => WorkspacesWorkspaceIdRoute,
  } as any)

const WorkspacesWorkspaceIdBillingSummaryRoute =
  WorkspacesWorkspaceIdBillingSummaryImport.update({
    id: '/summary',
    path: '/summary',
    getParentRoute: () => WorkspacesWorkspaceIdBillingRoute,
  } as any)

const WorkspacesWorkspaceIdBillingPlansRoute =
  WorkspacesWorkspaceIdBillingPlansImport.update({
    id: '/plans',
    path: '/plans',
    getParentRoute: () => WorkspacesWorkspaceIdBillingRoute,
  } as any)

const WorkspacesWorkspaceIdBillingAddOnsRoute =
  WorkspacesWorkspaceIdBillingAddOnsImport.update({
    id: '/add-ons',
    path: '/add-ons',
    getParentRoute: () => WorkspacesWorkspaceIdBillingRoute,
  } as any)

const WorkspacesWorkspaceIdIntegrationsIntegrationIdIndexRoute =
  WorkspacesWorkspaceIdIntegrationsIntegrationIdIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => WorkspacesWorkspaceIdIntegrationsIntegrationIdRoute,
  } as any)

const WorkspacesWorkspaceIdBotsBotIdIndexRoute =
  WorkspacesWorkspaceIdBotsBotIdIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => WorkspacesWorkspaceIdBotsBotIdRoute,
  } as any)

const WorkspacesWorkspaceIdIntegrationsIntegrationIdVersionsRoute =
  WorkspacesWorkspaceIdIntegrationsIntegrationIdVersionsImport.update({
    id: '/versions',
    path: '/versions',
    getParentRoute: () => WorkspacesWorkspaceIdIntegrationsIntegrationIdRoute,
  } as any)

const WorkspacesWorkspaceIdIntegrationsIntegrationIdSettingsRoute =
  WorkspacesWorkspaceIdIntegrationsIntegrationIdSettingsImport.update({
    id: '/settings',
    path: '/settings',
    getParentRoute: () => WorkspacesWorkspaceIdIntegrationsIntegrationIdRoute,
  } as any)

const WorkspacesWorkspaceIdIntegrationsIntegrationIdLogsRoute =
  WorkspacesWorkspaceIdIntegrationsIntegrationIdLogsImport.update({
    id: '/logs',
    path: '/logs',
    getParentRoute: () => WorkspacesWorkspaceIdIntegrationsIntegrationIdRoute,
  } as any)

const WorkspacesWorkspaceIdIntegrationsIntegrationIdSplatRoute =
  WorkspacesWorkspaceIdIntegrationsIntegrationIdSplatImport.update({
    id: '/$',
    path: '/$',
    getParentRoute: () => WorkspacesWorkspaceIdIntegrationsIntegrationIdRoute,
  } as any)

const WorkspacesWorkspaceIdChatbotsBotIdSplatRoute =
  WorkspacesWorkspaceIdChatbotsBotIdSplatImport.update({
    id: '/$',
    path: '/$',
    getParentRoute: () => WorkspacesWorkspaceIdChatbotsBotIdRoute,
  } as any)

const WorkspacesWorkspaceIdBotsBotIdWebchatRoute =
  WorkspacesWorkspaceIdBotsBotIdWebchatImport.update({
    id: '/webchat',
    path: '/webchat',
    getParentRoute: () => WorkspacesWorkspaceIdBotsBotIdRoute,
  } as any)

const WorkspacesWorkspaceIdBotsBotIdUsersRoute =
  WorkspacesWorkspaceIdBotsBotIdUsersImport.update({
    id: '/users',
    path: '/users',
    getParentRoute: () => WorkspacesWorkspaceIdBotsBotIdRoute,
  } as any)

const WorkspacesWorkspaceIdBotsBotIdOverviewRoute =
  WorkspacesWorkspaceIdBotsBotIdOverviewImport.update({
    id: '/overview',
    path: '/overview',
    getParentRoute: () => WorkspacesWorkspaceIdBotsBotIdRoute,
  } as any)

const WorkspacesWorkspaceIdBotsBotIdLogsRoute =
  WorkspacesWorkspaceIdBotsBotIdLogsImport.update({
    id: '/logs',
    path: '/logs',
    getParentRoute: () => WorkspacesWorkspaceIdBotsBotIdRoute,
  } as any)

const WorkspacesWorkspaceIdBotsBotIdHitlRoute =
  WorkspacesWorkspaceIdBotsBotIdHitlImport.update({
    id: '/hitl',
    path: '/hitl',
    getParentRoute: () => WorkspacesWorkspaceIdBotsBotIdRoute,
  } as any)

const WorkspacesWorkspaceIdBotsBotIdFilesRoute =
  WorkspacesWorkspaceIdBotsBotIdFilesImport.update({
    id: '/files',
    path: '/files',
    getParentRoute: () => WorkspacesWorkspaceIdBotsBotIdRoute,
  } as any)

const WorkspacesWorkspaceIdBotsBotIdEventsRoute =
  WorkspacesWorkspaceIdBotsBotIdEventsImport.update({
    id: '/events',
    path: '/events',
    getParentRoute: () => WorkspacesWorkspaceIdBotsBotIdRoute,
  } as any)

const WorkspacesWorkspaceIdBotsBotIdConfigurationVariablesRoute =
  WorkspacesWorkspaceIdBotsBotIdConfigurationVariablesImport.update({
    id: '/configuration-variables',
    path: '/configuration-variables',
    getParentRoute: () => WorkspacesWorkspaceIdBotsBotIdRoute,
  } as any)

const WorkspacesWorkspaceIdBotsBotIdAnalyticsRoute =
  WorkspacesWorkspaceIdBotsBotIdAnalyticsImport.update({
    id: '/analytics',
    path: '/analytics',
    getParentRoute: () => WorkspacesWorkspaceIdBotsBotIdRoute,
  } as any)

const WorkspacesWorkspaceIdBotsBotIdSplatRoute =
  WorkspacesWorkspaceIdBotsBotIdSplatImport.update({
    id: '/$',
    path: '/$',
    getParentRoute: () => WorkspacesWorkspaceIdBotsBotIdRoute,
  } as any)

const WorkspacesWorkspaceIdBotsBotIdWebchatIndexRoute =
  WorkspacesWorkspaceIdBotsBotIdWebchatIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => WorkspacesWorkspaceIdBotsBotIdWebchatRoute,
  } as any)

const WorkspacesWorkspaceIdBotsBotIdTablesIndexRoute =
  WorkspacesWorkspaceIdBotsBotIdTablesIndexImport.update({
    id: '/tables/',
    path: '/tables/',
    getParentRoute: () => WorkspacesWorkspaceIdBotsBotIdRoute,
  } as any)

const WorkspacesWorkspaceIdBotsBotIdIssuesIndexRoute =
  WorkspacesWorkspaceIdBotsBotIdIssuesIndexImport.update({
    id: '/issues/',
    path: '/issues/',
    getParentRoute: () => WorkspacesWorkspaceIdBotsBotIdRoute,
  } as any)

const WorkspacesWorkspaceIdBotsBotIdConversationsIndexRoute =
  WorkspacesWorkspaceIdBotsBotIdConversationsIndexImport.update({
    id: '/conversations/',
    path: '/conversations/',
    getParentRoute: () => WorkspacesWorkspaceIdBotsBotIdRoute,
  } as any)

const WorkspacesWorkspaceIdBotsBotIdTablesTableIdRoute =
  WorkspacesWorkspaceIdBotsBotIdTablesTableIdImport.update({
    id: '/tables/$tableId',
    path: '/tables/$tableId',
    getParentRoute: () => WorkspacesWorkspaceIdBotsBotIdRoute,
  } as any)

const WorkspacesWorkspaceIdBotsBotIdIssuesIssueIdRoute =
  WorkspacesWorkspaceIdBotsBotIdIssuesIssueIdImport.update({
    id: '/issues/$issueId',
    path: '/issues/$issueId',
    getParentRoute: () => WorkspacesWorkspaceIdBotsBotIdRoute,
  } as any)

const WorkspacesWorkspaceIdBotsBotIdConversationsConversationIdRoute =
  WorkspacesWorkspaceIdBotsBotIdConversationsConversationIdImport.update({
    id: '/conversations/$conversationId',
    path: '/conversations/$conversationId',
    getParentRoute: () => WorkspacesWorkspaceIdBotsBotIdRoute,
  } as any)

const WorkspacesWorkspaceIdBotsBotIdWebchatV2ThemeRoute =
  WorkspacesWorkspaceIdBotsBotIdWebchatV2ThemeImport.update({
    id: '/v2/theme',
    path: '/v2/theme',
    getParentRoute: () => WorkspacesWorkspaceIdBotsBotIdWebchatRoute,
  } as any)

const WorkspacesWorkspaceIdBotsBotIdWebchatV2ShareRoute =
  WorkspacesWorkspaceIdBotsBotIdWebchatV2ShareImport.update({
    id: '/v2/share',
    path: '/v2/share',
    getParentRoute: () => WorkspacesWorkspaceIdBotsBotIdWebchatRoute,
  } as any)

const WorkspacesWorkspaceIdBotsBotIdWebchatV2GeneralRoute =
  WorkspacesWorkspaceIdBotsBotIdWebchatV2GeneralImport.update({
    id: '/v2/general',
    path: '/v2/general',
    getParentRoute: () => WorkspacesWorkspaceIdBotsBotIdWebchatRoute,
  } as any)

const WorkspacesWorkspaceIdBotsBotIdWebchatV2AdvancedSettingsRoute =
  WorkspacesWorkspaceIdBotsBotIdWebchatV2AdvancedSettingsImport.update({
    id: '/v2/advanced-settings',
    path: '/v2/advanced-settings',
    getParentRoute: () => WorkspacesWorkspaceIdBotsBotIdWebchatRoute,
  } as any)

const WorkspacesWorkspaceIdBotsBotIdConversationsMessageMessageIdRoute =
  WorkspacesWorkspaceIdBotsBotIdConversationsMessageMessageIdImport.update({
    id: '/conversations/message/$messageId',
    path: '/conversations/message/$messageId',
    getParentRoute: () => WorkspacesWorkspaceIdBotsBotIdRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/$': {
      id: '/$'
      path: '/$'
      fullPath: '/$'
      preLoaderRoute: typeof SplatImport
      parentRoute: typeof rootRoute
    }
    '/forbidden': {
      id: '/forbidden'
      path: '/forbidden'
      fullPath: '/forbidden'
      preLoaderRoute: typeof ForbiddenImport
      parentRoute: typeof rootRoute
    }
    '/welcome': {
      id: '/welcome'
      path: '/welcome'
      fullPath: '/welcome'
      preLoaderRoute: typeof WelcomeImport
      parentRoute: typeof rootRoute
    }
    '/oauth/interstitial': {
      id: '/oauth/interstitial'
      path: '/oauth/interstitial'
      fullPath: '/oauth/interstitial'
      preLoaderRoute: typeof OauthInterstitialImport
      parentRoute: typeof rootRoute
    }
    '/profile/$workspaceId': {
      id: '/profile/$workspaceId'
      path: '/profile/$workspaceId'
      fullPath: '/profile/$workspaceId'
      preLoaderRoute: typeof ProfileWorkspaceIdImport
      parentRoute: typeof rootRoute
    }
    '/profile/settings': {
      id: '/profile/settings'
      path: '/profile/settings'
      fullPath: '/profile/settings'
      preLoaderRoute: typeof ProfileSettingsImport
      parentRoute: typeof rootRoute
    }
    '/workspaces/$workspaceId': {
      id: '/workspaces/$workspaceId'
      path: '/workspaces/$workspaceId'
      fullPath: '/workspaces/$workspaceId'
      preLoaderRoute: typeof WorkspacesWorkspaceIdImport
      parentRoute: typeof rootRoute
    }
    '/hub/': {
      id: '/hub/'
      path: '/hub'
      fullPath: '/hub'
      preLoaderRoute: typeof HubIndexImport
      parentRoute: typeof rootRoute
    }
    '/workspaces/': {
      id: '/workspaces/'
      path: '/workspaces'
      fullPath: '/workspaces'
      preLoaderRoute: typeof WorkspacesIndexImport
      parentRoute: typeof rootRoute
    }
    '/hub/integrations/$integrationId': {
      id: '/hub/integrations/$integrationId'
      path: '/hub/integrations/$integrationId'
      fullPath: '/hub/integrations/$integrationId'
      preLoaderRoute: typeof HubIntegrationsIntegrationIdImport
      parentRoute: typeof rootRoute
    }
    '/workspaces/$workspaceId/$': {
      id: '/workspaces/$workspaceId/$'
      path: '/$'
      fullPath: '/workspaces/$workspaceId/$'
      preLoaderRoute: typeof WorkspacesWorkspaceIdSplatImport
      parentRoute: typeof WorkspacesWorkspaceIdImport
    }
    '/workspaces/$workspaceId/billing': {
      id: '/workspaces/$workspaceId/billing'
      path: '/billing'
      fullPath: '/workspaces/$workspaceId/billing'
      preLoaderRoute: typeof WorkspacesWorkspaceIdBillingImport
      parentRoute: typeof WorkspacesWorkspaceIdImport
    }
    '/workspaces/$workspaceId/bots_': {
      id: '/workspaces/$workspaceId/bots_'
      path: '/bots'
      fullPath: '/workspaces/$workspaceId/bots'
      preLoaderRoute: typeof WorkspacesWorkspaceIdBotsImport
      parentRoute: typeof WorkspacesWorkspaceIdImport
    }
    '/workspaces/$workspaceId/chatbots_': {
      id: '/workspaces/$workspaceId/chatbots_'
      path: '/chatbots'
      fullPath: '/workspaces/$workspaceId/chatbots'
      preLoaderRoute: typeof WorkspacesWorkspaceIdChatbotsImport
      parentRoute: typeof WorkspacesWorkspaceIdImport
    }
    '/workspaces/$workspaceId/home': {
      id: '/workspaces/$workspaceId/home'
      path: '/home'
      fullPath: '/workspaces/$workspaceId/home'
      preLoaderRoute: typeof WorkspacesWorkspaceIdHomeImport
      parentRoute: typeof WorkspacesWorkspaceIdImport
    }
    '/workspaces/$workspaceId/settings': {
      id: '/workspaces/$workspaceId/settings'
      path: '/settings'
      fullPath: '/workspaces/$workspaceId/settings'
      preLoaderRoute: typeof WorkspacesWorkspaceIdSettingsImport
      parentRoute: typeof WorkspacesWorkspaceIdImport
    }
    '/workspaces/$workspaceId/usage': {
      id: '/workspaces/$workspaceId/usage'
      path: '/usage'
      fullPath: '/workspaces/$workspaceId/usage'
      preLoaderRoute: typeof WorkspacesWorkspaceIdUsageImport
      parentRoute: typeof WorkspacesWorkspaceIdImport
    }
    '/hub/integrations/': {
      id: '/hub/integrations/'
      path: '/hub/integrations'
      fullPath: '/hub/integrations'
      preLoaderRoute: typeof HubIntegrationsIndexImport
      parentRoute: typeof rootRoute
    }
    '/workspaces/$workspaceId/': {
      id: '/workspaces/$workspaceId/'
      path: '/'
      fullPath: '/workspaces/$workspaceId/'
      preLoaderRoute: typeof WorkspacesWorkspaceIdIndexImport
      parentRoute: typeof WorkspacesWorkspaceIdImport
    }
    '/workspaces/$workspaceId/billing/add-ons': {
      id: '/workspaces/$workspaceId/billing/add-ons'
      path: '/add-ons'
      fullPath: '/workspaces/$workspaceId/billing/add-ons'
      preLoaderRoute: typeof WorkspacesWorkspaceIdBillingAddOnsImport
      parentRoute: typeof WorkspacesWorkspaceIdBillingImport
    }
    '/workspaces/$workspaceId/billing/plans': {
      id: '/workspaces/$workspaceId/billing/plans'
      path: '/plans'
      fullPath: '/workspaces/$workspaceId/billing/plans'
      preLoaderRoute: typeof WorkspacesWorkspaceIdBillingPlansImport
      parentRoute: typeof WorkspacesWorkspaceIdBillingImport
    }
    '/workspaces/$workspaceId/billing/summary': {
      id: '/workspaces/$workspaceId/billing/summary'
      path: '/summary'
      fullPath: '/workspaces/$workspaceId/billing/summary'
      preLoaderRoute: typeof WorkspacesWorkspaceIdBillingSummaryImport
      parentRoute: typeof WorkspacesWorkspaceIdBillingImport
    }
    '/workspaces/$workspaceId/bots/$botId': {
      id: '/workspaces/$workspaceId/bots/$botId'
      path: '/bots/$botId'
      fullPath: '/workspaces/$workspaceId/bots/$botId'
      preLoaderRoute: typeof WorkspacesWorkspaceIdBotsBotIdImport
      parentRoute: typeof WorkspacesWorkspaceIdImport
    }
    '/workspaces/$workspaceId/chatbots/$botId': {
      id: '/workspaces/$workspaceId/chatbots/$botId'
      path: '/chatbots/$botId'
      fullPath: '/workspaces/$workspaceId/chatbots/$botId'
      preLoaderRoute: typeof WorkspacesWorkspaceIdChatbotsBotIdImport
      parentRoute: typeof WorkspacesWorkspaceIdImport
    }
    '/workspaces/$workspaceId/integrations/$integrationId': {
      id: '/workspaces/$workspaceId/integrations/$integrationId'
      path: '/integrations/$integrationId'
      fullPath: '/workspaces/$workspaceId/integrations/$integrationId'
      preLoaderRoute: typeof WorkspacesWorkspaceIdIntegrationsIntegrationIdImport
      parentRoute: typeof WorkspacesWorkspaceIdImport
    }
    '/workspaces/$workspaceId/profile/settings': {
      id: '/workspaces/$workspaceId/profile/settings'
      path: '/profile/settings'
      fullPath: '/workspaces/$workspaceId/profile/settings'
      preLoaderRoute: typeof WorkspacesWorkspaceIdProfileSettingsImport
      parentRoute: typeof WorkspacesWorkspaceIdImport
    }
    '/workspaces/$workspaceId/settings/$': {
      id: '/workspaces/$workspaceId/settings/$'
      path: '/$'
      fullPath: '/workspaces/$workspaceId/settings/$'
      preLoaderRoute: typeof WorkspacesWorkspaceIdSettingsSplatImport
      parentRoute: typeof WorkspacesWorkspaceIdSettingsImport
    }
    '/workspaces/$workspaceId/settings/audits': {
      id: '/workspaces/$workspaceId/settings/audits'
      path: '/audits'
      fullPath: '/workspaces/$workspaceId/settings/audits'
      preLoaderRoute: typeof WorkspacesWorkspaceIdSettingsAuditsImport
      parentRoute: typeof WorkspacesWorkspaceIdSettingsImport
    }
    '/workspaces/$workspaceId/settings/details': {
      id: '/workspaces/$workspaceId/settings/details'
      path: '/details'
      fullPath: '/workspaces/$workspaceId/settings/details'
      preLoaderRoute: typeof WorkspacesWorkspaceIdSettingsDetailsImport
      parentRoute: typeof WorkspacesWorkspaceIdSettingsImport
    }
    '/workspaces/$workspaceId/settings/members': {
      id: '/workspaces/$workspaceId/settings/members'
      path: '/members'
      fullPath: '/workspaces/$workspaceId/settings/members'
      preLoaderRoute: typeof WorkspacesWorkspaceIdSettingsMembersImport
      parentRoute: typeof WorkspacesWorkspaceIdSettingsImport
    }
    '/workspaces/$workspaceId/billing/': {
      id: '/workspaces/$workspaceId/billing/'
      path: '/'
      fullPath: '/workspaces/$workspaceId/billing/'
      preLoaderRoute: typeof WorkspacesWorkspaceIdBillingIndexImport
      parentRoute: typeof WorkspacesWorkspaceIdBillingImport
    }
    '/workspaces/$workspaceId/integrations/': {
      id: '/workspaces/$workspaceId/integrations/'
      path: '/integrations'
      fullPath: '/workspaces/$workspaceId/integrations'
      preLoaderRoute: typeof WorkspacesWorkspaceIdIntegrationsIndexImport
      parentRoute: typeof WorkspacesWorkspaceIdImport
    }
    '/workspaces/$workspaceId/settings/': {
      id: '/workspaces/$workspaceId/settings/'
      path: '/'
      fullPath: '/workspaces/$workspaceId/settings/'
      preLoaderRoute: typeof WorkspacesWorkspaceIdSettingsIndexImport
      parentRoute: typeof WorkspacesWorkspaceIdSettingsImport
    }
    '/workspaces/$workspaceId/bots/$botId/$': {
      id: '/workspaces/$workspaceId/bots/$botId/$'
      path: '/$'
      fullPath: '/workspaces/$workspaceId/bots/$botId/$'
      preLoaderRoute: typeof WorkspacesWorkspaceIdBotsBotIdSplatImport
      parentRoute: typeof WorkspacesWorkspaceIdBotsBotIdImport
    }
    '/workspaces/$workspaceId/bots/$botId/analytics': {
      id: '/workspaces/$workspaceId/bots/$botId/analytics'
      path: '/analytics'
      fullPath: '/workspaces/$workspaceId/bots/$botId/analytics'
      preLoaderRoute: typeof WorkspacesWorkspaceIdBotsBotIdAnalyticsImport
      parentRoute: typeof WorkspacesWorkspaceIdBotsBotIdImport
    }
    '/workspaces/$workspaceId/bots/$botId/configuration-variables': {
      id: '/workspaces/$workspaceId/bots/$botId/configuration-variables'
      path: '/configuration-variables'
      fullPath: '/workspaces/$workspaceId/bots/$botId/configuration-variables'
      preLoaderRoute: typeof WorkspacesWorkspaceIdBotsBotIdConfigurationVariablesImport
      parentRoute: typeof WorkspacesWorkspaceIdBotsBotIdImport
    }
    '/workspaces/$workspaceId/bots/$botId/events': {
      id: '/workspaces/$workspaceId/bots/$botId/events'
      path: '/events'
      fullPath: '/workspaces/$workspaceId/bots/$botId/events'
      preLoaderRoute: typeof WorkspacesWorkspaceIdBotsBotIdEventsImport
      parentRoute: typeof WorkspacesWorkspaceIdBotsBotIdImport
    }
    '/workspaces/$workspaceId/bots/$botId/files': {
      id: '/workspaces/$workspaceId/bots/$botId/files'
      path: '/files'
      fullPath: '/workspaces/$workspaceId/bots/$botId/files'
      preLoaderRoute: typeof WorkspacesWorkspaceIdBotsBotIdFilesImport
      parentRoute: typeof WorkspacesWorkspaceIdBotsBotIdImport
    }
    '/workspaces/$workspaceId/bots/$botId/hitl': {
      id: '/workspaces/$workspaceId/bots/$botId/hitl'
      path: '/hitl'
      fullPath: '/workspaces/$workspaceId/bots/$botId/hitl'
      preLoaderRoute: typeof WorkspacesWorkspaceIdBotsBotIdHitlImport
      parentRoute: typeof WorkspacesWorkspaceIdBotsBotIdImport
    }
    '/workspaces/$workspaceId/bots/$botId/logs': {
      id: '/workspaces/$workspaceId/bots/$botId/logs'
      path: '/logs'
      fullPath: '/workspaces/$workspaceId/bots/$botId/logs'
      preLoaderRoute: typeof WorkspacesWorkspaceIdBotsBotIdLogsImport
      parentRoute: typeof WorkspacesWorkspaceIdBotsBotIdImport
    }
    '/workspaces/$workspaceId/bots/$botId/overview': {
      id: '/workspaces/$workspaceId/bots/$botId/overview'
      path: '/overview'
      fullPath: '/workspaces/$workspaceId/bots/$botId/overview'
      preLoaderRoute: typeof WorkspacesWorkspaceIdBotsBotIdOverviewImport
      parentRoute: typeof WorkspacesWorkspaceIdBotsBotIdImport
    }
    '/workspaces/$workspaceId/bots/$botId/users': {
      id: '/workspaces/$workspaceId/bots/$botId/users'
      path: '/users'
      fullPath: '/workspaces/$workspaceId/bots/$botId/users'
      preLoaderRoute: typeof WorkspacesWorkspaceIdBotsBotIdUsersImport
      parentRoute: typeof WorkspacesWorkspaceIdBotsBotIdImport
    }
    '/workspaces/$workspaceId/bots/$botId/webchat': {
      id: '/workspaces/$workspaceId/bots/$botId/webchat'
      path: '/webchat'
      fullPath: '/workspaces/$workspaceId/bots/$botId/webchat'
      preLoaderRoute: typeof WorkspacesWorkspaceIdBotsBotIdWebchatImport
      parentRoute: typeof WorkspacesWorkspaceIdBotsBotIdImport
    }
    '/workspaces/$workspaceId/chatbots/$botId/$': {
      id: '/workspaces/$workspaceId/chatbots/$botId/$'
      path: '/$'
      fullPath: '/workspaces/$workspaceId/chatbots/$botId/$'
      preLoaderRoute: typeof WorkspacesWorkspaceIdChatbotsBotIdSplatImport
      parentRoute: typeof WorkspacesWorkspaceIdChatbotsBotIdImport
    }
    '/workspaces/$workspaceId/integrations/$integrationId/$': {
      id: '/workspaces/$workspaceId/integrations/$integrationId/$'
      path: '/$'
      fullPath: '/workspaces/$workspaceId/integrations/$integrationId/$'
      preLoaderRoute: typeof WorkspacesWorkspaceIdIntegrationsIntegrationIdSplatImport
      parentRoute: typeof WorkspacesWorkspaceIdIntegrationsIntegrationIdImport
    }
    '/workspaces/$workspaceId/integrations/$integrationId/logs': {
      id: '/workspaces/$workspaceId/integrations/$integrationId/logs'
      path: '/logs'
      fullPath: '/workspaces/$workspaceId/integrations/$integrationId/logs'
      preLoaderRoute: typeof WorkspacesWorkspaceIdIntegrationsIntegrationIdLogsImport
      parentRoute: typeof WorkspacesWorkspaceIdIntegrationsIntegrationIdImport
    }
    '/workspaces/$workspaceId/integrations/$integrationId/settings': {
      id: '/workspaces/$workspaceId/integrations/$integrationId/settings'
      path: '/settings'
      fullPath: '/workspaces/$workspaceId/integrations/$integrationId/settings'
      preLoaderRoute: typeof WorkspacesWorkspaceIdIntegrationsIntegrationIdSettingsImport
      parentRoute: typeof WorkspacesWorkspaceIdIntegrationsIntegrationIdImport
    }
    '/workspaces/$workspaceId/integrations/$integrationId/versions': {
      id: '/workspaces/$workspaceId/integrations/$integrationId/versions'
      path: '/versions'
      fullPath: '/workspaces/$workspaceId/integrations/$integrationId/versions'
      preLoaderRoute: typeof WorkspacesWorkspaceIdIntegrationsIntegrationIdVersionsImport
      parentRoute: typeof WorkspacesWorkspaceIdIntegrationsIntegrationIdImport
    }
    '/workspaces/$workspaceId/bots/$botId/': {
      id: '/workspaces/$workspaceId/bots/$botId/'
      path: '/'
      fullPath: '/workspaces/$workspaceId/bots/$botId/'
      preLoaderRoute: typeof WorkspacesWorkspaceIdBotsBotIdIndexImport
      parentRoute: typeof WorkspacesWorkspaceIdBotsBotIdImport
    }
    '/workspaces/$workspaceId/integrations/$integrationId/': {
      id: '/workspaces/$workspaceId/integrations/$integrationId/'
      path: '/'
      fullPath: '/workspaces/$workspaceId/integrations/$integrationId/'
      preLoaderRoute: typeof WorkspacesWorkspaceIdIntegrationsIntegrationIdIndexImport
      parentRoute: typeof WorkspacesWorkspaceIdIntegrationsIntegrationIdImport
    }
    '/workspaces/$workspaceId/bots/$botId/conversations/$conversationId': {
      id: '/workspaces/$workspaceId/bots/$botId/conversations/$conversationId'
      path: '/conversations/$conversationId'
      fullPath: '/workspaces/$workspaceId/bots/$botId/conversations/$conversationId'
      preLoaderRoute: typeof WorkspacesWorkspaceIdBotsBotIdConversationsConversationIdImport
      parentRoute: typeof WorkspacesWorkspaceIdBotsBotIdImport
    }
    '/workspaces/$workspaceId/bots/$botId/issues/$issueId': {
      id: '/workspaces/$workspaceId/bots/$botId/issues/$issueId'
      path: '/issues/$issueId'
      fullPath: '/workspaces/$workspaceId/bots/$botId/issues/$issueId'
      preLoaderRoute: typeof WorkspacesWorkspaceIdBotsBotIdIssuesIssueIdImport
      parentRoute: typeof WorkspacesWorkspaceIdBotsBotIdImport
    }
    '/workspaces/$workspaceId/bots/$botId/tables/$tableId': {
      id: '/workspaces/$workspaceId/bots/$botId/tables/$tableId'
      path: '/tables/$tableId'
      fullPath: '/workspaces/$workspaceId/bots/$botId/tables/$tableId'
      preLoaderRoute: typeof WorkspacesWorkspaceIdBotsBotIdTablesTableIdImport
      parentRoute: typeof WorkspacesWorkspaceIdBotsBotIdImport
    }
    '/workspaces/$workspaceId/bots/$botId/conversations/': {
      id: '/workspaces/$workspaceId/bots/$botId/conversations/'
      path: '/conversations'
      fullPath: '/workspaces/$workspaceId/bots/$botId/conversations'
      preLoaderRoute: typeof WorkspacesWorkspaceIdBotsBotIdConversationsIndexImport
      parentRoute: typeof WorkspacesWorkspaceIdBotsBotIdImport
    }
    '/workspaces/$workspaceId/bots/$botId/issues/': {
      id: '/workspaces/$workspaceId/bots/$botId/issues/'
      path: '/issues'
      fullPath: '/workspaces/$workspaceId/bots/$botId/issues'
      preLoaderRoute: typeof WorkspacesWorkspaceIdBotsBotIdIssuesIndexImport
      parentRoute: typeof WorkspacesWorkspaceIdBotsBotIdImport
    }
    '/workspaces/$workspaceId/bots/$botId/tables/': {
      id: '/workspaces/$workspaceId/bots/$botId/tables/'
      path: '/tables'
      fullPath: '/workspaces/$workspaceId/bots/$botId/tables'
      preLoaderRoute: typeof WorkspacesWorkspaceIdBotsBotIdTablesIndexImport
      parentRoute: typeof WorkspacesWorkspaceIdBotsBotIdImport
    }
    '/workspaces/$workspaceId/bots/$botId/webchat/': {
      id: '/workspaces/$workspaceId/bots/$botId/webchat/'
      path: '/'
      fullPath: '/workspaces/$workspaceId/bots/$botId/webchat/'
      preLoaderRoute: typeof WorkspacesWorkspaceIdBotsBotIdWebchatIndexImport
      parentRoute: typeof WorkspacesWorkspaceIdBotsBotIdWebchatImport
    }
    '/workspaces/$workspaceId/bots/$botId/conversations/message/$messageId': {
      id: '/workspaces/$workspaceId/bots/$botId/conversations/message/$messageId'
      path: '/conversations/message/$messageId'
      fullPath: '/workspaces/$workspaceId/bots/$botId/conversations/message/$messageId'
      preLoaderRoute: typeof WorkspacesWorkspaceIdBotsBotIdConversationsMessageMessageIdImport
      parentRoute: typeof WorkspacesWorkspaceIdBotsBotIdImport
    }
    '/workspaces/$workspaceId/bots/$botId/webchat/v2/advanced-settings': {
      id: '/workspaces/$workspaceId/bots/$botId/webchat/v2/advanced-settings'
      path: '/v2/advanced-settings'
      fullPath: '/workspaces/$workspaceId/bots/$botId/webchat/v2/advanced-settings'
      preLoaderRoute: typeof WorkspacesWorkspaceIdBotsBotIdWebchatV2AdvancedSettingsImport
      parentRoute: typeof WorkspacesWorkspaceIdBotsBotIdWebchatImport
    }
    '/workspaces/$workspaceId/bots/$botId/webchat/v2/general': {
      id: '/workspaces/$workspaceId/bots/$botId/webchat/v2/general'
      path: '/v2/general'
      fullPath: '/workspaces/$workspaceId/bots/$botId/webchat/v2/general'
      preLoaderRoute: typeof WorkspacesWorkspaceIdBotsBotIdWebchatV2GeneralImport
      parentRoute: typeof WorkspacesWorkspaceIdBotsBotIdWebchatImport
    }
    '/workspaces/$workspaceId/bots/$botId/webchat/v2/share': {
      id: '/workspaces/$workspaceId/bots/$botId/webchat/v2/share'
      path: '/v2/share'
      fullPath: '/workspaces/$workspaceId/bots/$botId/webchat/v2/share'
      preLoaderRoute: typeof WorkspacesWorkspaceIdBotsBotIdWebchatV2ShareImport
      parentRoute: typeof WorkspacesWorkspaceIdBotsBotIdWebchatImport
    }
    '/workspaces/$workspaceId/bots/$botId/webchat/v2/theme': {
      id: '/workspaces/$workspaceId/bots/$botId/webchat/v2/theme'
      path: '/v2/theme'
      fullPath: '/workspaces/$workspaceId/bots/$botId/webchat/v2/theme'
      preLoaderRoute: typeof WorkspacesWorkspaceIdBotsBotIdWebchatV2ThemeImport
      parentRoute: typeof WorkspacesWorkspaceIdBotsBotIdWebchatImport
    }
  }
}

// Create and export the route tree

interface WorkspacesWorkspaceIdBillingRouteChildren {
  WorkspacesWorkspaceIdBillingAddOnsRoute: typeof WorkspacesWorkspaceIdBillingAddOnsRoute
  WorkspacesWorkspaceIdBillingPlansRoute: typeof WorkspacesWorkspaceIdBillingPlansRoute
  WorkspacesWorkspaceIdBillingSummaryRoute: typeof WorkspacesWorkspaceIdBillingSummaryRoute
  WorkspacesWorkspaceIdBillingIndexRoute: typeof WorkspacesWorkspaceIdBillingIndexRoute
}

const WorkspacesWorkspaceIdBillingRouteChildren: WorkspacesWorkspaceIdBillingRouteChildren =
  {
    WorkspacesWorkspaceIdBillingAddOnsRoute:
      WorkspacesWorkspaceIdBillingAddOnsRoute,
    WorkspacesWorkspaceIdBillingPlansRoute:
      WorkspacesWorkspaceIdBillingPlansRoute,
    WorkspacesWorkspaceIdBillingSummaryRoute:
      WorkspacesWorkspaceIdBillingSummaryRoute,
    WorkspacesWorkspaceIdBillingIndexRoute:
      WorkspacesWorkspaceIdBillingIndexRoute,
  }

const WorkspacesWorkspaceIdBillingRouteWithChildren =
  WorkspacesWorkspaceIdBillingRoute._addFileChildren(
    WorkspacesWorkspaceIdBillingRouteChildren,
  )

interface WorkspacesWorkspaceIdSettingsRouteChildren {
  WorkspacesWorkspaceIdSettingsSplatRoute: typeof WorkspacesWorkspaceIdSettingsSplatRoute
  WorkspacesWorkspaceIdSettingsAuditsRoute: typeof WorkspacesWorkspaceIdSettingsAuditsRoute
  WorkspacesWorkspaceIdSettingsDetailsRoute: typeof WorkspacesWorkspaceIdSettingsDetailsRoute
  WorkspacesWorkspaceIdSettingsMembersRoute: typeof WorkspacesWorkspaceIdSettingsMembersRoute
  WorkspacesWorkspaceIdSettingsIndexRoute: typeof WorkspacesWorkspaceIdSettingsIndexRoute
}

const WorkspacesWorkspaceIdSettingsRouteChildren: WorkspacesWorkspaceIdSettingsRouteChildren =
  {
    WorkspacesWorkspaceIdSettingsSplatRoute:
      WorkspacesWorkspaceIdSettingsSplatRoute,
    WorkspacesWorkspaceIdSettingsAuditsRoute:
      WorkspacesWorkspaceIdSettingsAuditsRoute,
    WorkspacesWorkspaceIdSettingsDetailsRoute:
      WorkspacesWorkspaceIdSettingsDetailsRoute,
    WorkspacesWorkspaceIdSettingsMembersRoute:
      WorkspacesWorkspaceIdSettingsMembersRoute,
    WorkspacesWorkspaceIdSettingsIndexRoute:
      WorkspacesWorkspaceIdSettingsIndexRoute,
  }

const WorkspacesWorkspaceIdSettingsRouteWithChildren =
  WorkspacesWorkspaceIdSettingsRoute._addFileChildren(
    WorkspacesWorkspaceIdSettingsRouteChildren,
  )

interface WorkspacesWorkspaceIdBotsBotIdWebchatRouteChildren {
  WorkspacesWorkspaceIdBotsBotIdWebchatIndexRoute: typeof WorkspacesWorkspaceIdBotsBotIdWebchatIndexRoute
  WorkspacesWorkspaceIdBotsBotIdWebchatV2AdvancedSettingsRoute: typeof WorkspacesWorkspaceIdBotsBotIdWebchatV2AdvancedSettingsRoute
  WorkspacesWorkspaceIdBotsBotIdWebchatV2GeneralRoute: typeof WorkspacesWorkspaceIdBotsBotIdWebchatV2GeneralRoute
  WorkspacesWorkspaceIdBotsBotIdWebchatV2ShareRoute: typeof WorkspacesWorkspaceIdBotsBotIdWebchatV2ShareRoute
  WorkspacesWorkspaceIdBotsBotIdWebchatV2ThemeRoute: typeof WorkspacesWorkspaceIdBotsBotIdWebchatV2ThemeRoute
}

const WorkspacesWorkspaceIdBotsBotIdWebchatRouteChildren: WorkspacesWorkspaceIdBotsBotIdWebchatRouteChildren =
  {
    WorkspacesWorkspaceIdBotsBotIdWebchatIndexRoute:
      WorkspacesWorkspaceIdBotsBotIdWebchatIndexRoute,
    WorkspacesWorkspaceIdBotsBotIdWebchatV2AdvancedSettingsRoute:
      WorkspacesWorkspaceIdBotsBotIdWebchatV2AdvancedSettingsRoute,
    WorkspacesWorkspaceIdBotsBotIdWebchatV2GeneralRoute:
      WorkspacesWorkspaceIdBotsBotIdWebchatV2GeneralRoute,
    WorkspacesWorkspaceIdBotsBotIdWebchatV2ShareRoute:
      WorkspacesWorkspaceIdBotsBotIdWebchatV2ShareRoute,
    WorkspacesWorkspaceIdBotsBotIdWebchatV2ThemeRoute:
      WorkspacesWorkspaceIdBotsBotIdWebchatV2ThemeRoute,
  }

const WorkspacesWorkspaceIdBotsBotIdWebchatRouteWithChildren =
  WorkspacesWorkspaceIdBotsBotIdWebchatRoute._addFileChildren(
    WorkspacesWorkspaceIdBotsBotIdWebchatRouteChildren,
  )

interface WorkspacesWorkspaceIdBotsBotIdRouteChildren {
  WorkspacesWorkspaceIdBotsBotIdSplatRoute: typeof WorkspacesWorkspaceIdBotsBotIdSplatRoute
  WorkspacesWorkspaceIdBotsBotIdAnalyticsRoute: typeof WorkspacesWorkspaceIdBotsBotIdAnalyticsRoute
  WorkspacesWorkspaceIdBotsBotIdConfigurationVariablesRoute: typeof WorkspacesWorkspaceIdBotsBotIdConfigurationVariablesRoute
  WorkspacesWorkspaceIdBotsBotIdEventsRoute: typeof WorkspacesWorkspaceIdBotsBotIdEventsRoute
  WorkspacesWorkspaceIdBotsBotIdFilesRoute: typeof WorkspacesWorkspaceIdBotsBotIdFilesRoute
  WorkspacesWorkspaceIdBotsBotIdHitlRoute: typeof WorkspacesWorkspaceIdBotsBotIdHitlRoute
  WorkspacesWorkspaceIdBotsBotIdLogsRoute: typeof WorkspacesWorkspaceIdBotsBotIdLogsRoute
  WorkspacesWorkspaceIdBotsBotIdOverviewRoute: typeof WorkspacesWorkspaceIdBotsBotIdOverviewRoute
  WorkspacesWorkspaceIdBotsBotIdUsersRoute: typeof WorkspacesWorkspaceIdBotsBotIdUsersRoute
  WorkspacesWorkspaceIdBotsBotIdWebchatRoute: typeof WorkspacesWorkspaceIdBotsBotIdWebchatRouteWithChildren
  WorkspacesWorkspaceIdBotsBotIdIndexRoute: typeof WorkspacesWorkspaceIdBotsBotIdIndexRoute
  WorkspacesWorkspaceIdBotsBotIdConversationsConversationIdRoute: typeof WorkspacesWorkspaceIdBotsBotIdConversationsConversationIdRoute
  WorkspacesWorkspaceIdBotsBotIdIssuesIssueIdRoute: typeof WorkspacesWorkspaceIdBotsBotIdIssuesIssueIdRoute
  WorkspacesWorkspaceIdBotsBotIdTablesTableIdRoute: typeof WorkspacesWorkspaceIdBotsBotIdTablesTableIdRoute
  WorkspacesWorkspaceIdBotsBotIdConversationsIndexRoute: typeof WorkspacesWorkspaceIdBotsBotIdConversationsIndexRoute
  WorkspacesWorkspaceIdBotsBotIdIssuesIndexRoute: typeof WorkspacesWorkspaceIdBotsBotIdIssuesIndexRoute
  WorkspacesWorkspaceIdBotsBotIdTablesIndexRoute: typeof WorkspacesWorkspaceIdBotsBotIdTablesIndexRoute
  WorkspacesWorkspaceIdBotsBotIdConversationsMessageMessageIdRoute: typeof WorkspacesWorkspaceIdBotsBotIdConversationsMessageMessageIdRoute
}

const WorkspacesWorkspaceIdBotsBotIdRouteChildren: WorkspacesWorkspaceIdBotsBotIdRouteChildren =
  {
    WorkspacesWorkspaceIdBotsBotIdSplatRoute:
      WorkspacesWorkspaceIdBotsBotIdSplatRoute,
    WorkspacesWorkspaceIdBotsBotIdAnalyticsRoute:
      WorkspacesWorkspaceIdBotsBotIdAnalyticsRoute,
    WorkspacesWorkspaceIdBotsBotIdConfigurationVariablesRoute:
      WorkspacesWorkspaceIdBotsBotIdConfigurationVariablesRoute,
    WorkspacesWorkspaceIdBotsBotIdEventsRoute:
      WorkspacesWorkspaceIdBotsBotIdEventsRoute,
    WorkspacesWorkspaceIdBotsBotIdFilesRoute:
      WorkspacesWorkspaceIdBotsBotIdFilesRoute,
    WorkspacesWorkspaceIdBotsBotIdHitlRoute:
      WorkspacesWorkspaceIdBotsBotIdHitlRoute,
    WorkspacesWorkspaceIdBotsBotIdLogsRoute:
      WorkspacesWorkspaceIdBotsBotIdLogsRoute,
    WorkspacesWorkspaceIdBotsBotIdOverviewRoute:
      WorkspacesWorkspaceIdBotsBotIdOverviewRoute,
    WorkspacesWorkspaceIdBotsBotIdUsersRoute:
      WorkspacesWorkspaceIdBotsBotIdUsersRoute,
    WorkspacesWorkspaceIdBotsBotIdWebchatRoute:
      WorkspacesWorkspaceIdBotsBotIdWebchatRouteWithChildren,
    WorkspacesWorkspaceIdBotsBotIdIndexRoute:
      WorkspacesWorkspaceIdBotsBotIdIndexRoute,
    WorkspacesWorkspaceIdBotsBotIdConversationsConversationIdRoute:
      WorkspacesWorkspaceIdBotsBotIdConversationsConversationIdRoute,
    WorkspacesWorkspaceIdBotsBotIdIssuesIssueIdRoute:
      WorkspacesWorkspaceIdBotsBotIdIssuesIssueIdRoute,
    WorkspacesWorkspaceIdBotsBotIdTablesTableIdRoute:
      WorkspacesWorkspaceIdBotsBotIdTablesTableIdRoute,
    WorkspacesWorkspaceIdBotsBotIdConversationsIndexRoute:
      WorkspacesWorkspaceIdBotsBotIdConversationsIndexRoute,
    WorkspacesWorkspaceIdBotsBotIdIssuesIndexRoute:
      WorkspacesWorkspaceIdBotsBotIdIssuesIndexRoute,
    WorkspacesWorkspaceIdBotsBotIdTablesIndexRoute:
      WorkspacesWorkspaceIdBotsBotIdTablesIndexRoute,
    WorkspacesWorkspaceIdBotsBotIdConversationsMessageMessageIdRoute:
      WorkspacesWorkspaceIdBotsBotIdConversationsMessageMessageIdRoute,
  }

const WorkspacesWorkspaceIdBotsBotIdRouteWithChildren =
  WorkspacesWorkspaceIdBotsBotIdRoute._addFileChildren(
    WorkspacesWorkspaceIdBotsBotIdRouteChildren,
  )

interface WorkspacesWorkspaceIdChatbotsBotIdRouteChildren {
  WorkspacesWorkspaceIdChatbotsBotIdSplatRoute: typeof WorkspacesWorkspaceIdChatbotsBotIdSplatRoute
}

const WorkspacesWorkspaceIdChatbotsBotIdRouteChildren: WorkspacesWorkspaceIdChatbotsBotIdRouteChildren =
  {
    WorkspacesWorkspaceIdChatbotsBotIdSplatRoute:
      WorkspacesWorkspaceIdChatbotsBotIdSplatRoute,
  }

const WorkspacesWorkspaceIdChatbotsBotIdRouteWithChildren =
  WorkspacesWorkspaceIdChatbotsBotIdRoute._addFileChildren(
    WorkspacesWorkspaceIdChatbotsBotIdRouteChildren,
  )

interface WorkspacesWorkspaceIdIntegrationsIntegrationIdRouteChildren {
  WorkspacesWorkspaceIdIntegrationsIntegrationIdSplatRoute: typeof WorkspacesWorkspaceIdIntegrationsIntegrationIdSplatRoute
  WorkspacesWorkspaceIdIntegrationsIntegrationIdLogsRoute: typeof WorkspacesWorkspaceIdIntegrationsIntegrationIdLogsRoute
  WorkspacesWorkspaceIdIntegrationsIntegrationIdSettingsRoute: typeof WorkspacesWorkspaceIdIntegrationsIntegrationIdSettingsRoute
  WorkspacesWorkspaceIdIntegrationsIntegrationIdVersionsRoute: typeof WorkspacesWorkspaceIdIntegrationsIntegrationIdVersionsRoute
  WorkspacesWorkspaceIdIntegrationsIntegrationIdIndexRoute: typeof WorkspacesWorkspaceIdIntegrationsIntegrationIdIndexRoute
}

const WorkspacesWorkspaceIdIntegrationsIntegrationIdRouteChildren: WorkspacesWorkspaceIdIntegrationsIntegrationIdRouteChildren =
  {
    WorkspacesWorkspaceIdIntegrationsIntegrationIdSplatRoute:
      WorkspacesWorkspaceIdIntegrationsIntegrationIdSplatRoute,
    WorkspacesWorkspaceIdIntegrationsIntegrationIdLogsRoute:
      WorkspacesWorkspaceIdIntegrationsIntegrationIdLogsRoute,
    WorkspacesWorkspaceIdIntegrationsIntegrationIdSettingsRoute:
      WorkspacesWorkspaceIdIntegrationsIntegrationIdSettingsRoute,
    WorkspacesWorkspaceIdIntegrationsIntegrationIdVersionsRoute:
      WorkspacesWorkspaceIdIntegrationsIntegrationIdVersionsRoute,
    WorkspacesWorkspaceIdIntegrationsIntegrationIdIndexRoute:
      WorkspacesWorkspaceIdIntegrationsIntegrationIdIndexRoute,
  }

const WorkspacesWorkspaceIdIntegrationsIntegrationIdRouteWithChildren =
  WorkspacesWorkspaceIdIntegrationsIntegrationIdRoute._addFileChildren(
    WorkspacesWorkspaceIdIntegrationsIntegrationIdRouteChildren,
  )

interface WorkspacesWorkspaceIdRouteChildren {
  WorkspacesWorkspaceIdSplatRoute: typeof WorkspacesWorkspaceIdSplatRoute
  WorkspacesWorkspaceIdBillingRoute: typeof WorkspacesWorkspaceIdBillingRouteWithChildren
  WorkspacesWorkspaceIdBotsRoute: typeof WorkspacesWorkspaceIdBotsRoute
  WorkspacesWorkspaceIdChatbotsRoute: typeof WorkspacesWorkspaceIdChatbotsRoute
  WorkspacesWorkspaceIdHomeRoute: typeof WorkspacesWorkspaceIdHomeRoute
  WorkspacesWorkspaceIdSettingsRoute: typeof WorkspacesWorkspaceIdSettingsRouteWithChildren
  WorkspacesWorkspaceIdUsageRoute: typeof WorkspacesWorkspaceIdUsageRoute
  WorkspacesWorkspaceIdIndexRoute: typeof WorkspacesWorkspaceIdIndexRoute
  WorkspacesWorkspaceIdBotsBotIdRoute: typeof WorkspacesWorkspaceIdBotsBotIdRouteWithChildren
  WorkspacesWorkspaceIdChatbotsBotIdRoute: typeof WorkspacesWorkspaceIdChatbotsBotIdRouteWithChildren
  WorkspacesWorkspaceIdIntegrationsIntegrationIdRoute: typeof WorkspacesWorkspaceIdIntegrationsIntegrationIdRouteWithChildren
  WorkspacesWorkspaceIdProfileSettingsRoute: typeof WorkspacesWorkspaceIdProfileSettingsRoute
  WorkspacesWorkspaceIdIntegrationsIndexRoute: typeof WorkspacesWorkspaceIdIntegrationsIndexRoute
}

const WorkspacesWorkspaceIdRouteChildren: WorkspacesWorkspaceIdRouteChildren = {
  WorkspacesWorkspaceIdSplatRoute: WorkspacesWorkspaceIdSplatRoute,
  WorkspacesWorkspaceIdBillingRoute:
    WorkspacesWorkspaceIdBillingRouteWithChildren,
  WorkspacesWorkspaceIdBotsRoute: WorkspacesWorkspaceIdBotsRoute,
  WorkspacesWorkspaceIdChatbotsRoute: WorkspacesWorkspaceIdChatbotsRoute,
  WorkspacesWorkspaceIdHomeRoute: WorkspacesWorkspaceIdHomeRoute,
  WorkspacesWorkspaceIdSettingsRoute:
    WorkspacesWorkspaceIdSettingsRouteWithChildren,
  WorkspacesWorkspaceIdUsageRoute: WorkspacesWorkspaceIdUsageRoute,
  WorkspacesWorkspaceIdIndexRoute: WorkspacesWorkspaceIdIndexRoute,
  WorkspacesWorkspaceIdBotsBotIdRoute:
    WorkspacesWorkspaceIdBotsBotIdRouteWithChildren,
  WorkspacesWorkspaceIdChatbotsBotIdRoute:
    WorkspacesWorkspaceIdChatbotsBotIdRouteWithChildren,
  WorkspacesWorkspaceIdIntegrationsIntegrationIdRoute:
    WorkspacesWorkspaceIdIntegrationsIntegrationIdRouteWithChildren,
  WorkspacesWorkspaceIdProfileSettingsRoute:
    WorkspacesWorkspaceIdProfileSettingsRoute,
  WorkspacesWorkspaceIdIntegrationsIndexRoute:
    WorkspacesWorkspaceIdIntegrationsIndexRoute,
}

const WorkspacesWorkspaceIdRouteWithChildren =
  WorkspacesWorkspaceIdRoute._addFileChildren(
    WorkspacesWorkspaceIdRouteChildren,
  )

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/$': typeof SplatRoute
  '/forbidden': typeof ForbiddenRoute
  '/welcome': typeof WelcomeRoute
  '/oauth/interstitial': typeof OauthInterstitialRoute
  '/profile/$workspaceId': typeof ProfileWorkspaceIdRoute
  '/profile/settings': typeof ProfileSettingsRoute
  '/workspaces/$workspaceId': typeof WorkspacesWorkspaceIdRouteWithChildren
  '/hub': typeof HubIndexRoute
  '/workspaces': typeof WorkspacesIndexRoute
  '/hub/integrations/$integrationId': typeof HubIntegrationsIntegrationIdRoute
  '/workspaces/$workspaceId/$': typeof WorkspacesWorkspaceIdSplatRoute
  '/workspaces/$workspaceId/billing': typeof WorkspacesWorkspaceIdBillingRouteWithChildren
  '/workspaces/$workspaceId/bots': typeof WorkspacesWorkspaceIdBotsRoute
  '/workspaces/$workspaceId/chatbots': typeof WorkspacesWorkspaceIdChatbotsRoute
  '/workspaces/$workspaceId/home': typeof WorkspacesWorkspaceIdHomeRoute
  '/workspaces/$workspaceId/settings': typeof WorkspacesWorkspaceIdSettingsRouteWithChildren
  '/workspaces/$workspaceId/usage': typeof WorkspacesWorkspaceIdUsageRoute
  '/hub/integrations': typeof HubIntegrationsIndexRoute
  '/workspaces/$workspaceId/': typeof WorkspacesWorkspaceIdIndexRoute
  '/workspaces/$workspaceId/billing/add-ons': typeof WorkspacesWorkspaceIdBillingAddOnsRoute
  '/workspaces/$workspaceId/billing/plans': typeof WorkspacesWorkspaceIdBillingPlansRoute
  '/workspaces/$workspaceId/billing/summary': typeof WorkspacesWorkspaceIdBillingSummaryRoute
  '/workspaces/$workspaceId/bots/$botId': typeof WorkspacesWorkspaceIdBotsBotIdRouteWithChildren
  '/workspaces/$workspaceId/chatbots/$botId': typeof WorkspacesWorkspaceIdChatbotsBotIdRouteWithChildren
  '/workspaces/$workspaceId/integrations/$integrationId': typeof WorkspacesWorkspaceIdIntegrationsIntegrationIdRouteWithChildren
  '/workspaces/$workspaceId/profile/settings': typeof WorkspacesWorkspaceIdProfileSettingsRoute
  '/workspaces/$workspaceId/settings/$': typeof WorkspacesWorkspaceIdSettingsSplatRoute
  '/workspaces/$workspaceId/settings/audits': typeof WorkspacesWorkspaceIdSettingsAuditsRoute
  '/workspaces/$workspaceId/settings/details': typeof WorkspacesWorkspaceIdSettingsDetailsRoute
  '/workspaces/$workspaceId/settings/members': typeof WorkspacesWorkspaceIdSettingsMembersRoute
  '/workspaces/$workspaceId/billing/': typeof WorkspacesWorkspaceIdBillingIndexRoute
  '/workspaces/$workspaceId/integrations': typeof WorkspacesWorkspaceIdIntegrationsIndexRoute
  '/workspaces/$workspaceId/settings/': typeof WorkspacesWorkspaceIdSettingsIndexRoute
  '/workspaces/$workspaceId/bots/$botId/$': typeof WorkspacesWorkspaceIdBotsBotIdSplatRoute
  '/workspaces/$workspaceId/bots/$botId/analytics': typeof WorkspacesWorkspaceIdBotsBotIdAnalyticsRoute
  '/workspaces/$workspaceId/bots/$botId/configuration-variables': typeof WorkspacesWorkspaceIdBotsBotIdConfigurationVariablesRoute
  '/workspaces/$workspaceId/bots/$botId/events': typeof WorkspacesWorkspaceIdBotsBotIdEventsRoute
  '/workspaces/$workspaceId/bots/$botId/files': typeof WorkspacesWorkspaceIdBotsBotIdFilesRoute
  '/workspaces/$workspaceId/bots/$botId/hitl': typeof WorkspacesWorkspaceIdBotsBotIdHitlRoute
  '/workspaces/$workspaceId/bots/$botId/logs': typeof WorkspacesWorkspaceIdBotsBotIdLogsRoute
  '/workspaces/$workspaceId/bots/$botId/overview': typeof WorkspacesWorkspaceIdBotsBotIdOverviewRoute
  '/workspaces/$workspaceId/bots/$botId/users': typeof WorkspacesWorkspaceIdBotsBotIdUsersRoute
  '/workspaces/$workspaceId/bots/$botId/webchat': typeof WorkspacesWorkspaceIdBotsBotIdWebchatRouteWithChildren
  '/workspaces/$workspaceId/chatbots/$botId/$': typeof WorkspacesWorkspaceIdChatbotsBotIdSplatRoute
  '/workspaces/$workspaceId/integrations/$integrationId/$': typeof WorkspacesWorkspaceIdIntegrationsIntegrationIdSplatRoute
  '/workspaces/$workspaceId/integrations/$integrationId/logs': typeof WorkspacesWorkspaceIdIntegrationsIntegrationIdLogsRoute
  '/workspaces/$workspaceId/integrations/$integrationId/settings': typeof WorkspacesWorkspaceIdIntegrationsIntegrationIdSettingsRoute
  '/workspaces/$workspaceId/integrations/$integrationId/versions': typeof WorkspacesWorkspaceIdIntegrationsIntegrationIdVersionsRoute
  '/workspaces/$workspaceId/bots/$botId/': typeof WorkspacesWorkspaceIdBotsBotIdIndexRoute
  '/workspaces/$workspaceId/integrations/$integrationId/': typeof WorkspacesWorkspaceIdIntegrationsIntegrationIdIndexRoute
  '/workspaces/$workspaceId/bots/$botId/conversations/$conversationId': typeof WorkspacesWorkspaceIdBotsBotIdConversationsConversationIdRoute
  '/workspaces/$workspaceId/bots/$botId/issues/$issueId': typeof WorkspacesWorkspaceIdBotsBotIdIssuesIssueIdRoute
  '/workspaces/$workspaceId/bots/$botId/tables/$tableId': typeof WorkspacesWorkspaceIdBotsBotIdTablesTableIdRoute
  '/workspaces/$workspaceId/bots/$botId/conversations': typeof WorkspacesWorkspaceIdBotsBotIdConversationsIndexRoute
  '/workspaces/$workspaceId/bots/$botId/issues': typeof WorkspacesWorkspaceIdBotsBotIdIssuesIndexRoute
  '/workspaces/$workspaceId/bots/$botId/tables': typeof WorkspacesWorkspaceIdBotsBotIdTablesIndexRoute
  '/workspaces/$workspaceId/bots/$botId/webchat/': typeof WorkspacesWorkspaceIdBotsBotIdWebchatIndexRoute
  '/workspaces/$workspaceId/bots/$botId/conversations/message/$messageId': typeof WorkspacesWorkspaceIdBotsBotIdConversationsMessageMessageIdRoute
  '/workspaces/$workspaceId/bots/$botId/webchat/v2/advanced-settings': typeof WorkspacesWorkspaceIdBotsBotIdWebchatV2AdvancedSettingsRoute
  '/workspaces/$workspaceId/bots/$botId/webchat/v2/general': typeof WorkspacesWorkspaceIdBotsBotIdWebchatV2GeneralRoute
  '/workspaces/$workspaceId/bots/$botId/webchat/v2/share': typeof WorkspacesWorkspaceIdBotsBotIdWebchatV2ShareRoute
  '/workspaces/$workspaceId/bots/$botId/webchat/v2/theme': typeof WorkspacesWorkspaceIdBotsBotIdWebchatV2ThemeRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/$': typeof SplatRoute
  '/forbidden': typeof ForbiddenRoute
  '/welcome': typeof WelcomeRoute
  '/oauth/interstitial': typeof OauthInterstitialRoute
  '/profile/$workspaceId': typeof ProfileWorkspaceIdRoute
  '/profile/settings': typeof ProfileSettingsRoute
  '/hub': typeof HubIndexRoute
  '/workspaces': typeof WorkspacesIndexRoute
  '/hub/integrations/$integrationId': typeof HubIntegrationsIntegrationIdRoute
  '/workspaces/$workspaceId/$': typeof WorkspacesWorkspaceIdSplatRoute
  '/workspaces/$workspaceId/bots': typeof WorkspacesWorkspaceIdBotsRoute
  '/workspaces/$workspaceId/chatbots': typeof WorkspacesWorkspaceIdChatbotsRoute
  '/workspaces/$workspaceId/home': typeof WorkspacesWorkspaceIdHomeRoute
  '/workspaces/$workspaceId/usage': typeof WorkspacesWorkspaceIdUsageRoute
  '/hub/integrations': typeof HubIntegrationsIndexRoute
  '/workspaces/$workspaceId': typeof WorkspacesWorkspaceIdIndexRoute
  '/workspaces/$workspaceId/billing/add-ons': typeof WorkspacesWorkspaceIdBillingAddOnsRoute
  '/workspaces/$workspaceId/billing/plans': typeof WorkspacesWorkspaceIdBillingPlansRoute
  '/workspaces/$workspaceId/billing/summary': typeof WorkspacesWorkspaceIdBillingSummaryRoute
  '/workspaces/$workspaceId/chatbots/$botId': typeof WorkspacesWorkspaceIdChatbotsBotIdRouteWithChildren
  '/workspaces/$workspaceId/profile/settings': typeof WorkspacesWorkspaceIdProfileSettingsRoute
  '/workspaces/$workspaceId/settings/$': typeof WorkspacesWorkspaceIdSettingsSplatRoute
  '/workspaces/$workspaceId/settings/audits': typeof WorkspacesWorkspaceIdSettingsAuditsRoute
  '/workspaces/$workspaceId/settings/details': typeof WorkspacesWorkspaceIdSettingsDetailsRoute
  '/workspaces/$workspaceId/settings/members': typeof WorkspacesWorkspaceIdSettingsMembersRoute
  '/workspaces/$workspaceId/billing': typeof WorkspacesWorkspaceIdBillingIndexRoute
  '/workspaces/$workspaceId/integrations': typeof WorkspacesWorkspaceIdIntegrationsIndexRoute
  '/workspaces/$workspaceId/settings': typeof WorkspacesWorkspaceIdSettingsIndexRoute
  '/workspaces/$workspaceId/bots/$botId/$': typeof WorkspacesWorkspaceIdBotsBotIdSplatRoute
  '/workspaces/$workspaceId/bots/$botId/analytics': typeof WorkspacesWorkspaceIdBotsBotIdAnalyticsRoute
  '/workspaces/$workspaceId/bots/$botId/configuration-variables': typeof WorkspacesWorkspaceIdBotsBotIdConfigurationVariablesRoute
  '/workspaces/$workspaceId/bots/$botId/events': typeof WorkspacesWorkspaceIdBotsBotIdEventsRoute
  '/workspaces/$workspaceId/bots/$botId/files': typeof WorkspacesWorkspaceIdBotsBotIdFilesRoute
  '/workspaces/$workspaceId/bots/$botId/hitl': typeof WorkspacesWorkspaceIdBotsBotIdHitlRoute
  '/workspaces/$workspaceId/bots/$botId/logs': typeof WorkspacesWorkspaceIdBotsBotIdLogsRoute
  '/workspaces/$workspaceId/bots/$botId/overview': typeof WorkspacesWorkspaceIdBotsBotIdOverviewRoute
  '/workspaces/$workspaceId/bots/$botId/users': typeof WorkspacesWorkspaceIdBotsBotIdUsersRoute
  '/workspaces/$workspaceId/chatbots/$botId/$': typeof WorkspacesWorkspaceIdChatbotsBotIdSplatRoute
  '/workspaces/$workspaceId/integrations/$integrationId/$': typeof WorkspacesWorkspaceIdIntegrationsIntegrationIdSplatRoute
  '/workspaces/$workspaceId/integrations/$integrationId/logs': typeof WorkspacesWorkspaceIdIntegrationsIntegrationIdLogsRoute
  '/workspaces/$workspaceId/integrations/$integrationId/settings': typeof WorkspacesWorkspaceIdIntegrationsIntegrationIdSettingsRoute
  '/workspaces/$workspaceId/integrations/$integrationId/versions': typeof WorkspacesWorkspaceIdIntegrationsIntegrationIdVersionsRoute
  '/workspaces/$workspaceId/bots/$botId': typeof WorkspacesWorkspaceIdBotsBotIdIndexRoute
  '/workspaces/$workspaceId/integrations/$integrationId': typeof WorkspacesWorkspaceIdIntegrationsIntegrationIdIndexRoute
  '/workspaces/$workspaceId/bots/$botId/conversations/$conversationId': typeof WorkspacesWorkspaceIdBotsBotIdConversationsConversationIdRoute
  '/workspaces/$workspaceId/bots/$botId/issues/$issueId': typeof WorkspacesWorkspaceIdBotsBotIdIssuesIssueIdRoute
  '/workspaces/$workspaceId/bots/$botId/tables/$tableId': typeof WorkspacesWorkspaceIdBotsBotIdTablesTableIdRoute
  '/workspaces/$workspaceId/bots/$botId/conversations': typeof WorkspacesWorkspaceIdBotsBotIdConversationsIndexRoute
  '/workspaces/$workspaceId/bots/$botId/issues': typeof WorkspacesWorkspaceIdBotsBotIdIssuesIndexRoute
  '/workspaces/$workspaceId/bots/$botId/tables': typeof WorkspacesWorkspaceIdBotsBotIdTablesIndexRoute
  '/workspaces/$workspaceId/bots/$botId/webchat': typeof WorkspacesWorkspaceIdBotsBotIdWebchatIndexRoute
  '/workspaces/$workspaceId/bots/$botId/conversations/message/$messageId': typeof WorkspacesWorkspaceIdBotsBotIdConversationsMessageMessageIdRoute
  '/workspaces/$workspaceId/bots/$botId/webchat/v2/advanced-settings': typeof WorkspacesWorkspaceIdBotsBotIdWebchatV2AdvancedSettingsRoute
  '/workspaces/$workspaceId/bots/$botId/webchat/v2/general': typeof WorkspacesWorkspaceIdBotsBotIdWebchatV2GeneralRoute
  '/workspaces/$workspaceId/bots/$botId/webchat/v2/share': typeof WorkspacesWorkspaceIdBotsBotIdWebchatV2ShareRoute
  '/workspaces/$workspaceId/bots/$botId/webchat/v2/theme': typeof WorkspacesWorkspaceIdBotsBotIdWebchatV2ThemeRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/$': typeof SplatRoute
  '/forbidden': typeof ForbiddenRoute
  '/welcome': typeof WelcomeRoute
  '/oauth/interstitial': typeof OauthInterstitialRoute
  '/profile/$workspaceId': typeof ProfileWorkspaceIdRoute
  '/profile/settings': typeof ProfileSettingsRoute
  '/workspaces/$workspaceId': typeof WorkspacesWorkspaceIdRouteWithChildren
  '/hub/': typeof HubIndexRoute
  '/workspaces/': typeof WorkspacesIndexRoute
  '/hub/integrations/$integrationId': typeof HubIntegrationsIntegrationIdRoute
  '/workspaces/$workspaceId/$': typeof WorkspacesWorkspaceIdSplatRoute
  '/workspaces/$workspaceId/billing': typeof WorkspacesWorkspaceIdBillingRouteWithChildren
  '/workspaces/$workspaceId/bots_': typeof WorkspacesWorkspaceIdBotsRoute
  '/workspaces/$workspaceId/chatbots_': typeof WorkspacesWorkspaceIdChatbotsRoute
  '/workspaces/$workspaceId/home': typeof WorkspacesWorkspaceIdHomeRoute
  '/workspaces/$workspaceId/settings': typeof WorkspacesWorkspaceIdSettingsRouteWithChildren
  '/workspaces/$workspaceId/usage': typeof WorkspacesWorkspaceIdUsageRoute
  '/hub/integrations/': typeof HubIntegrationsIndexRoute
  '/workspaces/$workspaceId/': typeof WorkspacesWorkspaceIdIndexRoute
  '/workspaces/$workspaceId/billing/add-ons': typeof WorkspacesWorkspaceIdBillingAddOnsRoute
  '/workspaces/$workspaceId/billing/plans': typeof WorkspacesWorkspaceIdBillingPlansRoute
  '/workspaces/$workspaceId/billing/summary': typeof WorkspacesWorkspaceIdBillingSummaryRoute
  '/workspaces/$workspaceId/bots/$botId': typeof WorkspacesWorkspaceIdBotsBotIdRouteWithChildren
  '/workspaces/$workspaceId/chatbots/$botId': typeof WorkspacesWorkspaceIdChatbotsBotIdRouteWithChildren
  '/workspaces/$workspaceId/integrations/$integrationId': typeof WorkspacesWorkspaceIdIntegrationsIntegrationIdRouteWithChildren
  '/workspaces/$workspaceId/profile/settings': typeof WorkspacesWorkspaceIdProfileSettingsRoute
  '/workspaces/$workspaceId/settings/$': typeof WorkspacesWorkspaceIdSettingsSplatRoute
  '/workspaces/$workspaceId/settings/audits': typeof WorkspacesWorkspaceIdSettingsAuditsRoute
  '/workspaces/$workspaceId/settings/details': typeof WorkspacesWorkspaceIdSettingsDetailsRoute
  '/workspaces/$workspaceId/settings/members': typeof WorkspacesWorkspaceIdSettingsMembersRoute
  '/workspaces/$workspaceId/billing/': typeof WorkspacesWorkspaceIdBillingIndexRoute
  '/workspaces/$workspaceId/integrations/': typeof WorkspacesWorkspaceIdIntegrationsIndexRoute
  '/workspaces/$workspaceId/settings/': typeof WorkspacesWorkspaceIdSettingsIndexRoute
  '/workspaces/$workspaceId/bots/$botId/$': typeof WorkspacesWorkspaceIdBotsBotIdSplatRoute
  '/workspaces/$workspaceId/bots/$botId/analytics': typeof WorkspacesWorkspaceIdBotsBotIdAnalyticsRoute
  '/workspaces/$workspaceId/bots/$botId/configuration-variables': typeof WorkspacesWorkspaceIdBotsBotIdConfigurationVariablesRoute
  '/workspaces/$workspaceId/bots/$botId/events': typeof WorkspacesWorkspaceIdBotsBotIdEventsRoute
  '/workspaces/$workspaceId/bots/$botId/files': typeof WorkspacesWorkspaceIdBotsBotIdFilesRoute
  '/workspaces/$workspaceId/bots/$botId/hitl': typeof WorkspacesWorkspaceIdBotsBotIdHitlRoute
  '/workspaces/$workspaceId/bots/$botId/logs': typeof WorkspacesWorkspaceIdBotsBotIdLogsRoute
  '/workspaces/$workspaceId/bots/$botId/overview': typeof WorkspacesWorkspaceIdBotsBotIdOverviewRoute
  '/workspaces/$workspaceId/bots/$botId/users': typeof WorkspacesWorkspaceIdBotsBotIdUsersRoute
  '/workspaces/$workspaceId/bots/$botId/webchat': typeof WorkspacesWorkspaceIdBotsBotIdWebchatRouteWithChildren
  '/workspaces/$workspaceId/chatbots/$botId/$': typeof WorkspacesWorkspaceIdChatbotsBotIdSplatRoute
  '/workspaces/$workspaceId/integrations/$integrationId/$': typeof WorkspacesWorkspaceIdIntegrationsIntegrationIdSplatRoute
  '/workspaces/$workspaceId/integrations/$integrationId/logs': typeof WorkspacesWorkspaceIdIntegrationsIntegrationIdLogsRoute
  '/workspaces/$workspaceId/integrations/$integrationId/settings': typeof WorkspacesWorkspaceIdIntegrationsIntegrationIdSettingsRoute
  '/workspaces/$workspaceId/integrations/$integrationId/versions': typeof WorkspacesWorkspaceIdIntegrationsIntegrationIdVersionsRoute
  '/workspaces/$workspaceId/bots/$botId/': typeof WorkspacesWorkspaceIdBotsBotIdIndexRoute
  '/workspaces/$workspaceId/integrations/$integrationId/': typeof WorkspacesWorkspaceIdIntegrationsIntegrationIdIndexRoute
  '/workspaces/$workspaceId/bots/$botId/conversations/$conversationId': typeof WorkspacesWorkspaceIdBotsBotIdConversationsConversationIdRoute
  '/workspaces/$workspaceId/bots/$botId/issues/$issueId': typeof WorkspacesWorkspaceIdBotsBotIdIssuesIssueIdRoute
  '/workspaces/$workspaceId/bots/$botId/tables/$tableId': typeof WorkspacesWorkspaceIdBotsBotIdTablesTableIdRoute
  '/workspaces/$workspaceId/bots/$botId/conversations/': typeof WorkspacesWorkspaceIdBotsBotIdConversationsIndexRoute
  '/workspaces/$workspaceId/bots/$botId/issues/': typeof WorkspacesWorkspaceIdBotsBotIdIssuesIndexRoute
  '/workspaces/$workspaceId/bots/$botId/tables/': typeof WorkspacesWorkspaceIdBotsBotIdTablesIndexRoute
  '/workspaces/$workspaceId/bots/$botId/webchat/': typeof WorkspacesWorkspaceIdBotsBotIdWebchatIndexRoute
  '/workspaces/$workspaceId/bots/$botId/conversations/message/$messageId': typeof WorkspacesWorkspaceIdBotsBotIdConversationsMessageMessageIdRoute
  '/workspaces/$workspaceId/bots/$botId/webchat/v2/advanced-settings': typeof WorkspacesWorkspaceIdBotsBotIdWebchatV2AdvancedSettingsRoute
  '/workspaces/$workspaceId/bots/$botId/webchat/v2/general': typeof WorkspacesWorkspaceIdBotsBotIdWebchatV2GeneralRoute
  '/workspaces/$workspaceId/bots/$botId/webchat/v2/share': typeof WorkspacesWorkspaceIdBotsBotIdWebchatV2ShareRoute
  '/workspaces/$workspaceId/bots/$botId/webchat/v2/theme': typeof WorkspacesWorkspaceIdBotsBotIdWebchatV2ThemeRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/$'
    | '/forbidden'
    | '/welcome'
    | '/oauth/interstitial'
    | '/profile/$workspaceId'
    | '/profile/settings'
    | '/workspaces/$workspaceId'
    | '/hub'
    | '/workspaces'
    | '/hub/integrations/$integrationId'
    | '/workspaces/$workspaceId/$'
    | '/workspaces/$workspaceId/billing'
    | '/workspaces/$workspaceId/bots'
    | '/workspaces/$workspaceId/chatbots'
    | '/workspaces/$workspaceId/home'
    | '/workspaces/$workspaceId/settings'
    | '/workspaces/$workspaceId/usage'
    | '/hub/integrations'
    | '/workspaces/$workspaceId/'
    | '/workspaces/$workspaceId/billing/add-ons'
    | '/workspaces/$workspaceId/billing/plans'
    | '/workspaces/$workspaceId/billing/summary'
    | '/workspaces/$workspaceId/bots/$botId'
    | '/workspaces/$workspaceId/chatbots/$botId'
    | '/workspaces/$workspaceId/integrations/$integrationId'
    | '/workspaces/$workspaceId/profile/settings'
    | '/workspaces/$workspaceId/settings/$'
    | '/workspaces/$workspaceId/settings/audits'
    | '/workspaces/$workspaceId/settings/details'
    | '/workspaces/$workspaceId/settings/members'
    | '/workspaces/$workspaceId/billing/'
    | '/workspaces/$workspaceId/integrations'
    | '/workspaces/$workspaceId/settings/'
    | '/workspaces/$workspaceId/bots/$botId/$'
    | '/workspaces/$workspaceId/bots/$botId/analytics'
    | '/workspaces/$workspaceId/bots/$botId/configuration-variables'
    | '/workspaces/$workspaceId/bots/$botId/events'
    | '/workspaces/$workspaceId/bots/$botId/files'
    | '/workspaces/$workspaceId/bots/$botId/hitl'
    | '/workspaces/$workspaceId/bots/$botId/logs'
    | '/workspaces/$workspaceId/bots/$botId/overview'
    | '/workspaces/$workspaceId/bots/$botId/users'
    | '/workspaces/$workspaceId/bots/$botId/webchat'
    | '/workspaces/$workspaceId/chatbots/$botId/$'
    | '/workspaces/$workspaceId/integrations/$integrationId/$'
    | '/workspaces/$workspaceId/integrations/$integrationId/logs'
    | '/workspaces/$workspaceId/integrations/$integrationId/settings'
    | '/workspaces/$workspaceId/integrations/$integrationId/versions'
    | '/workspaces/$workspaceId/bots/$botId/'
    | '/workspaces/$workspaceId/integrations/$integrationId/'
    | '/workspaces/$workspaceId/bots/$botId/conversations/$conversationId'
    | '/workspaces/$workspaceId/bots/$botId/issues/$issueId'
    | '/workspaces/$workspaceId/bots/$botId/tables/$tableId'
    | '/workspaces/$workspaceId/bots/$botId/conversations'
    | '/workspaces/$workspaceId/bots/$botId/issues'
    | '/workspaces/$workspaceId/bots/$botId/tables'
    | '/workspaces/$workspaceId/bots/$botId/webchat/'
    | '/workspaces/$workspaceId/bots/$botId/conversations/message/$messageId'
    | '/workspaces/$workspaceId/bots/$botId/webchat/v2/advanced-settings'
    | '/workspaces/$workspaceId/bots/$botId/webchat/v2/general'
    | '/workspaces/$workspaceId/bots/$botId/webchat/v2/share'
    | '/workspaces/$workspaceId/bots/$botId/webchat/v2/theme'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/$'
    | '/forbidden'
    | '/welcome'
    | '/oauth/interstitial'
    | '/profile/$workspaceId'
    | '/profile/settings'
    | '/hub'
    | '/workspaces'
    | '/hub/integrations/$integrationId'
    | '/workspaces/$workspaceId/$'
    | '/workspaces/$workspaceId/bots'
    | '/workspaces/$workspaceId/chatbots'
    | '/workspaces/$workspaceId/home'
    | '/workspaces/$workspaceId/usage'
    | '/hub/integrations'
    | '/workspaces/$workspaceId'
    | '/workspaces/$workspaceId/billing/add-ons'
    | '/workspaces/$workspaceId/billing/plans'
    | '/workspaces/$workspaceId/billing/summary'
    | '/workspaces/$workspaceId/chatbots/$botId'
    | '/workspaces/$workspaceId/profile/settings'
    | '/workspaces/$workspaceId/settings/$'
    | '/workspaces/$workspaceId/settings/audits'
    | '/workspaces/$workspaceId/settings/details'
    | '/workspaces/$workspaceId/settings/members'
    | '/workspaces/$workspaceId/billing'
    | '/workspaces/$workspaceId/integrations'
    | '/workspaces/$workspaceId/settings'
    | '/workspaces/$workspaceId/bots/$botId/$'
    | '/workspaces/$workspaceId/bots/$botId/analytics'
    | '/workspaces/$workspaceId/bots/$botId/configuration-variables'
    | '/workspaces/$workspaceId/bots/$botId/events'
    | '/workspaces/$workspaceId/bots/$botId/files'
    | '/workspaces/$workspaceId/bots/$botId/hitl'
    | '/workspaces/$workspaceId/bots/$botId/logs'
    | '/workspaces/$workspaceId/bots/$botId/overview'
    | '/workspaces/$workspaceId/bots/$botId/users'
    | '/workspaces/$workspaceId/chatbots/$botId/$'
    | '/workspaces/$workspaceId/integrations/$integrationId/$'
    | '/workspaces/$workspaceId/integrations/$integrationId/logs'
    | '/workspaces/$workspaceId/integrations/$integrationId/settings'
    | '/workspaces/$workspaceId/integrations/$integrationId/versions'
    | '/workspaces/$workspaceId/bots/$botId'
    | '/workspaces/$workspaceId/integrations/$integrationId'
    | '/workspaces/$workspaceId/bots/$botId/conversations/$conversationId'
    | '/workspaces/$workspaceId/bots/$botId/issues/$issueId'
    | '/workspaces/$workspaceId/bots/$botId/tables/$tableId'
    | '/workspaces/$workspaceId/bots/$botId/conversations'
    | '/workspaces/$workspaceId/bots/$botId/issues'
    | '/workspaces/$workspaceId/bots/$botId/tables'
    | '/workspaces/$workspaceId/bots/$botId/webchat'
    | '/workspaces/$workspaceId/bots/$botId/conversations/message/$messageId'
    | '/workspaces/$workspaceId/bots/$botId/webchat/v2/advanced-settings'
    | '/workspaces/$workspaceId/bots/$botId/webchat/v2/general'
    | '/workspaces/$workspaceId/bots/$botId/webchat/v2/share'
    | '/workspaces/$workspaceId/bots/$botId/webchat/v2/theme'
  id:
    | '__root__'
    | '/'
    | '/$'
    | '/forbidden'
    | '/welcome'
    | '/oauth/interstitial'
    | '/profile/$workspaceId'
    | '/profile/settings'
    | '/workspaces/$workspaceId'
    | '/hub/'
    | '/workspaces/'
    | '/hub/integrations/$integrationId'
    | '/workspaces/$workspaceId/$'
    | '/workspaces/$workspaceId/billing'
    | '/workspaces/$workspaceId/bots_'
    | '/workspaces/$workspaceId/chatbots_'
    | '/workspaces/$workspaceId/home'
    | '/workspaces/$workspaceId/settings'
    | '/workspaces/$workspaceId/usage'
    | '/hub/integrations/'
    | '/workspaces/$workspaceId/'
    | '/workspaces/$workspaceId/billing/add-ons'
    | '/workspaces/$workspaceId/billing/plans'
    | '/workspaces/$workspaceId/billing/summary'
    | '/workspaces/$workspaceId/bots/$botId'
    | '/workspaces/$workspaceId/chatbots/$botId'
    | '/workspaces/$workspaceId/integrations/$integrationId'
    | '/workspaces/$workspaceId/profile/settings'
    | '/workspaces/$workspaceId/settings/$'
    | '/workspaces/$workspaceId/settings/audits'
    | '/workspaces/$workspaceId/settings/details'
    | '/workspaces/$workspaceId/settings/members'
    | '/workspaces/$workspaceId/billing/'
    | '/workspaces/$workspaceId/integrations/'
    | '/workspaces/$workspaceId/settings/'
    | '/workspaces/$workspaceId/bots/$botId/$'
    | '/workspaces/$workspaceId/bots/$botId/analytics'
    | '/workspaces/$workspaceId/bots/$botId/configuration-variables'
    | '/workspaces/$workspaceId/bots/$botId/events'
    | '/workspaces/$workspaceId/bots/$botId/files'
    | '/workspaces/$workspaceId/bots/$botId/hitl'
    | '/workspaces/$workspaceId/bots/$botId/logs'
    | '/workspaces/$workspaceId/bots/$botId/overview'
    | '/workspaces/$workspaceId/bots/$botId/users'
    | '/workspaces/$workspaceId/bots/$botId/webchat'
    | '/workspaces/$workspaceId/chatbots/$botId/$'
    | '/workspaces/$workspaceId/integrations/$integrationId/$'
    | '/workspaces/$workspaceId/integrations/$integrationId/logs'
    | '/workspaces/$workspaceId/integrations/$integrationId/settings'
    | '/workspaces/$workspaceId/integrations/$integrationId/versions'
    | '/workspaces/$workspaceId/bots/$botId/'
    | '/workspaces/$workspaceId/integrations/$integrationId/'
    | '/workspaces/$workspaceId/bots/$botId/conversations/$conversationId'
    | '/workspaces/$workspaceId/bots/$botId/issues/$issueId'
    | '/workspaces/$workspaceId/bots/$botId/tables/$tableId'
    | '/workspaces/$workspaceId/bots/$botId/conversations/'
    | '/workspaces/$workspaceId/bots/$botId/issues/'
    | '/workspaces/$workspaceId/bots/$botId/tables/'
    | '/workspaces/$workspaceId/bots/$botId/webchat/'
    | '/workspaces/$workspaceId/bots/$botId/conversations/message/$messageId'
    | '/workspaces/$workspaceId/bots/$botId/webchat/v2/advanced-settings'
    | '/workspaces/$workspaceId/bots/$botId/webchat/v2/general'
    | '/workspaces/$workspaceId/bots/$botId/webchat/v2/share'
    | '/workspaces/$workspaceId/bots/$botId/webchat/v2/theme'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  SplatRoute: typeof SplatRoute
  ForbiddenRoute: typeof ForbiddenRoute
  WelcomeRoute: typeof WelcomeRoute
  OauthInterstitialRoute: typeof OauthInterstitialRoute
  ProfileWorkspaceIdRoute: typeof ProfileWorkspaceIdRoute
  ProfileSettingsRoute: typeof ProfileSettingsRoute
  WorkspacesWorkspaceIdRoute: typeof WorkspacesWorkspaceIdRouteWithChildren
  HubIndexRoute: typeof HubIndexRoute
  WorkspacesIndexRoute: typeof WorkspacesIndexRoute
  HubIntegrationsIntegrationIdRoute: typeof HubIntegrationsIntegrationIdRoute
  HubIntegrationsIndexRoute: typeof HubIntegrationsIndexRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  SplatRoute: SplatRoute,
  ForbiddenRoute: ForbiddenRoute,
  WelcomeRoute: WelcomeRoute,
  OauthInterstitialRoute: OauthInterstitialRoute,
  ProfileWorkspaceIdRoute: ProfileWorkspaceIdRoute,
  ProfileSettingsRoute: ProfileSettingsRoute,
  WorkspacesWorkspaceIdRoute: WorkspacesWorkspaceIdRouteWithChildren,
  HubIndexRoute: HubIndexRoute,
  WorkspacesIndexRoute: WorkspacesIndexRoute,
  HubIntegrationsIntegrationIdRoute: HubIntegrationsIntegrationIdRoute,
  HubIntegrationsIndexRoute: HubIntegrationsIndexRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/$",
        "/forbidden",
        "/welcome",
        "/oauth/interstitial",
        "/profile/$workspaceId",
        "/profile/settings",
        "/workspaces/$workspaceId",
        "/hub/",
        "/workspaces/",
        "/hub/integrations/$integrationId",
        "/hub/integrations/"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/$": {
      "filePath": "$.tsx"
    },
    "/forbidden": {
      "filePath": "forbidden.tsx"
    },
    "/welcome": {
      "filePath": "welcome.tsx"
    },
    "/oauth/interstitial": {
      "filePath": "oauth/interstitial.tsx"
    },
    "/profile/$workspaceId": {
      "filePath": "profile/$workspaceId.tsx"
    },
    "/profile/settings": {
      "filePath": "profile/settings.tsx"
    },
    "/workspaces/$workspaceId": {
      "filePath": "workspaces/$workspaceId.tsx",
      "children": [
        "/workspaces/$workspaceId/$",
        "/workspaces/$workspaceId/billing",
        "/workspaces/$workspaceId/bots_",
        "/workspaces/$workspaceId/chatbots_",
        "/workspaces/$workspaceId/home",
        "/workspaces/$workspaceId/settings",
        "/workspaces/$workspaceId/usage",
        "/workspaces/$workspaceId/",
        "/workspaces/$workspaceId/bots/$botId",
        "/workspaces/$workspaceId/chatbots/$botId",
        "/workspaces/$workspaceId/integrations/$integrationId",
        "/workspaces/$workspaceId/profile/settings",
        "/workspaces/$workspaceId/integrations/"
      ]
    },
    "/hub/": {
      "filePath": "hub/index.tsx"
    },
    "/workspaces/": {
      "filePath": "workspaces/index.tsx"
    },
    "/hub/integrations/$integrationId": {
      "filePath": "hub/integrations/$integrationId.tsx"
    },
    "/workspaces/$workspaceId/$": {
      "filePath": "workspaces/$workspaceId/$.tsx",
      "parent": "/workspaces/$workspaceId"
    },
    "/workspaces/$workspaceId/billing": {
      "filePath": "workspaces/$workspaceId/billing.tsx",
      "parent": "/workspaces/$workspaceId",
      "children": [
        "/workspaces/$workspaceId/billing/add-ons",
        "/workspaces/$workspaceId/billing/plans",
        "/workspaces/$workspaceId/billing/summary",
        "/workspaces/$workspaceId/billing/"
      ]
    },
    "/workspaces/$workspaceId/bots_": {
      "filePath": "workspaces/$workspaceId/bots_.tsx",
      "parent": "/workspaces/$workspaceId"
    },
    "/workspaces/$workspaceId/chatbots_": {
      "filePath": "workspaces/$workspaceId/chatbots_.tsx",
      "parent": "/workspaces/$workspaceId"
    },
    "/workspaces/$workspaceId/home": {
      "filePath": "workspaces/$workspaceId/home.tsx",
      "parent": "/workspaces/$workspaceId"
    },
    "/workspaces/$workspaceId/settings": {
      "filePath": "workspaces/$workspaceId/settings.tsx",
      "parent": "/workspaces/$workspaceId",
      "children": [
        "/workspaces/$workspaceId/settings/$",
        "/workspaces/$workspaceId/settings/audits",
        "/workspaces/$workspaceId/settings/details",
        "/workspaces/$workspaceId/settings/members",
        "/workspaces/$workspaceId/settings/"
      ]
    },
    "/workspaces/$workspaceId/usage": {
      "filePath": "workspaces/$workspaceId/usage.tsx",
      "parent": "/workspaces/$workspaceId"
    },
    "/hub/integrations/": {
      "filePath": "hub/integrations/index.tsx"
    },
    "/workspaces/$workspaceId/": {
      "filePath": "workspaces/$workspaceId/index.tsx",
      "parent": "/workspaces/$workspaceId"
    },
    "/workspaces/$workspaceId/billing/add-ons": {
      "filePath": "workspaces/$workspaceId/billing/add-ons.tsx",
      "parent": "/workspaces/$workspaceId/billing"
    },
    "/workspaces/$workspaceId/billing/plans": {
      "filePath": "workspaces/$workspaceId/billing/plans.tsx",
      "parent": "/workspaces/$workspaceId/billing"
    },
    "/workspaces/$workspaceId/billing/summary": {
      "filePath": "workspaces/$workspaceId/billing/summary.tsx",
      "parent": "/workspaces/$workspaceId/billing"
    },
    "/workspaces/$workspaceId/bots/$botId": {
      "filePath": "workspaces/$workspaceId/bots/$botId.tsx",
      "parent": "/workspaces/$workspaceId",
      "children": [
        "/workspaces/$workspaceId/bots/$botId/$",
        "/workspaces/$workspaceId/bots/$botId/analytics",
        "/workspaces/$workspaceId/bots/$botId/configuration-variables",
        "/workspaces/$workspaceId/bots/$botId/events",
        "/workspaces/$workspaceId/bots/$botId/files",
        "/workspaces/$workspaceId/bots/$botId/hitl",
        "/workspaces/$workspaceId/bots/$botId/logs",
        "/workspaces/$workspaceId/bots/$botId/overview",
        "/workspaces/$workspaceId/bots/$botId/users",
        "/workspaces/$workspaceId/bots/$botId/webchat",
        "/workspaces/$workspaceId/bots/$botId/",
        "/workspaces/$workspaceId/bots/$botId/conversations/$conversationId",
        "/workspaces/$workspaceId/bots/$botId/issues/$issueId",
        "/workspaces/$workspaceId/bots/$botId/tables/$tableId",
        "/workspaces/$workspaceId/bots/$botId/conversations/",
        "/workspaces/$workspaceId/bots/$botId/issues/",
        "/workspaces/$workspaceId/bots/$botId/tables/",
        "/workspaces/$workspaceId/bots/$botId/conversations/message/$messageId"
      ]
    },
    "/workspaces/$workspaceId/chatbots/$botId": {
      "filePath": "workspaces/$workspaceId/chatbots/$botId.tsx",
      "parent": "/workspaces/$workspaceId",
      "children": [
        "/workspaces/$workspaceId/chatbots/$botId/$"
      ]
    },
    "/workspaces/$workspaceId/integrations/$integrationId": {
      "filePath": "workspaces/$workspaceId/integrations/$integrationId.tsx",
      "parent": "/workspaces/$workspaceId",
      "children": [
        "/workspaces/$workspaceId/integrations/$integrationId/$",
        "/workspaces/$workspaceId/integrations/$integrationId/logs",
        "/workspaces/$workspaceId/integrations/$integrationId/settings",
        "/workspaces/$workspaceId/integrations/$integrationId/versions",
        "/workspaces/$workspaceId/integrations/$integrationId/"
      ]
    },
    "/workspaces/$workspaceId/profile/settings": {
      "filePath": "workspaces/$workspaceId/profile/settings.tsx",
      "parent": "/workspaces/$workspaceId"
    },
    "/workspaces/$workspaceId/settings/$": {
      "filePath": "workspaces/$workspaceId/settings/$.tsx",
      "parent": "/workspaces/$workspaceId/settings"
    },
    "/workspaces/$workspaceId/settings/audits": {
      "filePath": "workspaces/$workspaceId/settings/audits.tsx",
      "parent": "/workspaces/$workspaceId/settings"
    },
    "/workspaces/$workspaceId/settings/details": {
      "filePath": "workspaces/$workspaceId/settings/details.tsx",
      "parent": "/workspaces/$workspaceId/settings"
    },
    "/workspaces/$workspaceId/settings/members": {
      "filePath": "workspaces/$workspaceId/settings/members.tsx",
      "parent": "/workspaces/$workspaceId/settings"
    },
    "/workspaces/$workspaceId/billing/": {
      "filePath": "workspaces/$workspaceId/billing/index.tsx",
      "parent": "/workspaces/$workspaceId/billing"
    },
    "/workspaces/$workspaceId/integrations/": {
      "filePath": "workspaces/$workspaceId/integrations/index.tsx",
      "parent": "/workspaces/$workspaceId"
    },
    "/workspaces/$workspaceId/settings/": {
      "filePath": "workspaces/$workspaceId/settings/index.tsx",
      "parent": "/workspaces/$workspaceId/settings"
    },
    "/workspaces/$workspaceId/bots/$botId/$": {
      "filePath": "workspaces/$workspaceId/bots/$botId/$.tsx",
      "parent": "/workspaces/$workspaceId/bots/$botId"
    },
    "/workspaces/$workspaceId/bots/$botId/analytics": {
      "filePath": "workspaces/$workspaceId/bots/$botId/analytics.tsx",
      "parent": "/workspaces/$workspaceId/bots/$botId"
    },
    "/workspaces/$workspaceId/bots/$botId/configuration-variables": {
      "filePath": "workspaces/$workspaceId/bots/$botId/configuration-variables.tsx",
      "parent": "/workspaces/$workspaceId/bots/$botId"
    },
    "/workspaces/$workspaceId/bots/$botId/events": {
      "filePath": "workspaces/$workspaceId/bots/$botId/events.tsx",
      "parent": "/workspaces/$workspaceId/bots/$botId"
    },
    "/workspaces/$workspaceId/bots/$botId/files": {
      "filePath": "workspaces/$workspaceId/bots/$botId/files.tsx",
      "parent": "/workspaces/$workspaceId/bots/$botId"
    },
    "/workspaces/$workspaceId/bots/$botId/hitl": {
      "filePath": "workspaces/$workspaceId/bots/$botId/hitl.tsx",
      "parent": "/workspaces/$workspaceId/bots/$botId"
    },
    "/workspaces/$workspaceId/bots/$botId/logs": {
      "filePath": "workspaces/$workspaceId/bots/$botId/logs.tsx",
      "parent": "/workspaces/$workspaceId/bots/$botId"
    },
    "/workspaces/$workspaceId/bots/$botId/overview": {
      "filePath": "workspaces/$workspaceId/bots/$botId/overview.tsx",
      "parent": "/workspaces/$workspaceId/bots/$botId"
    },
    "/workspaces/$workspaceId/bots/$botId/users": {
      "filePath": "workspaces/$workspaceId/bots/$botId/users.tsx",
      "parent": "/workspaces/$workspaceId/bots/$botId"
    },
    "/workspaces/$workspaceId/bots/$botId/webchat": {
      "filePath": "workspaces/$workspaceId/bots/$botId/webchat.tsx",
      "parent": "/workspaces/$workspaceId/bots/$botId",
      "children": [
        "/workspaces/$workspaceId/bots/$botId/webchat/",
        "/workspaces/$workspaceId/bots/$botId/webchat/v2/advanced-settings",
        "/workspaces/$workspaceId/bots/$botId/webchat/v2/general",
        "/workspaces/$workspaceId/bots/$botId/webchat/v2/share",
        "/workspaces/$workspaceId/bots/$botId/webchat/v2/theme"
      ]
    },
    "/workspaces/$workspaceId/chatbots/$botId/$": {
      "filePath": "workspaces/$workspaceId/chatbots/$botId/$.tsx",
      "parent": "/workspaces/$workspaceId/chatbots/$botId"
    },
    "/workspaces/$workspaceId/integrations/$integrationId/$": {
      "filePath": "workspaces/$workspaceId/integrations/$integrationId/$.tsx",
      "parent": "/workspaces/$workspaceId/integrations/$integrationId"
    },
    "/workspaces/$workspaceId/integrations/$integrationId/logs": {
      "filePath": "workspaces/$workspaceId/integrations/$integrationId/logs.tsx",
      "parent": "/workspaces/$workspaceId/integrations/$integrationId"
    },
    "/workspaces/$workspaceId/integrations/$integrationId/settings": {
      "filePath": "workspaces/$workspaceId/integrations/$integrationId/settings.tsx",
      "parent": "/workspaces/$workspaceId/integrations/$integrationId"
    },
    "/workspaces/$workspaceId/integrations/$integrationId/versions": {
      "filePath": "workspaces/$workspaceId/integrations/$integrationId/versions.tsx",
      "parent": "/workspaces/$workspaceId/integrations/$integrationId"
    },
    "/workspaces/$workspaceId/bots/$botId/": {
      "filePath": "workspaces/$workspaceId/bots/$botId/index.tsx",
      "parent": "/workspaces/$workspaceId/bots/$botId"
    },
    "/workspaces/$workspaceId/integrations/$integrationId/": {
      "filePath": "workspaces/$workspaceId/integrations/$integrationId/index.tsx",
      "parent": "/workspaces/$workspaceId/integrations/$integrationId"
    },
    "/workspaces/$workspaceId/bots/$botId/conversations/$conversationId": {
      "filePath": "workspaces/$workspaceId/bots/$botId/conversations/$conversationId.tsx",
      "parent": "/workspaces/$workspaceId/bots/$botId"
    },
    "/workspaces/$workspaceId/bots/$botId/issues/$issueId": {
      "filePath": "workspaces/$workspaceId/bots/$botId/issues/$issueId.tsx",
      "parent": "/workspaces/$workspaceId/bots/$botId"
    },
    "/workspaces/$workspaceId/bots/$botId/tables/$tableId": {
      "filePath": "workspaces/$workspaceId/bots/$botId/tables/$tableId.tsx",
      "parent": "/workspaces/$workspaceId/bots/$botId"
    },
    "/workspaces/$workspaceId/bots/$botId/conversations/": {
      "filePath": "workspaces/$workspaceId/bots/$botId/conversations/index.tsx",
      "parent": "/workspaces/$workspaceId/bots/$botId"
    },
    "/workspaces/$workspaceId/bots/$botId/issues/": {
      "filePath": "workspaces/$workspaceId/bots/$botId/issues/index.tsx",
      "parent": "/workspaces/$workspaceId/bots/$botId"
    },
    "/workspaces/$workspaceId/bots/$botId/tables/": {
      "filePath": "workspaces/$workspaceId/bots/$botId/tables/index.tsx",
      "parent": "/workspaces/$workspaceId/bots/$botId"
    },
    "/workspaces/$workspaceId/bots/$botId/webchat/": {
      "filePath": "workspaces/$workspaceId/bots/$botId/webchat/index.tsx",
      "parent": "/workspaces/$workspaceId/bots/$botId/webchat"
    },
    "/workspaces/$workspaceId/bots/$botId/conversations/message/$messageId": {
      "filePath": "workspaces/$workspaceId/bots/$botId/conversations/message/$messageId.tsx",
      "parent": "/workspaces/$workspaceId/bots/$botId"
    },
    "/workspaces/$workspaceId/bots/$botId/webchat/v2/advanced-settings": {
      "filePath": "workspaces/$workspaceId/bots/$botId/webchat/v2/advanced-settings.tsx",
      "parent": "/workspaces/$workspaceId/bots/$botId/webchat"
    },
    "/workspaces/$workspaceId/bots/$botId/webchat/v2/general": {
      "filePath": "workspaces/$workspaceId/bots/$botId/webchat/v2/general.tsx",
      "parent": "/workspaces/$workspaceId/bots/$botId/webchat"
    },
    "/workspaces/$workspaceId/bots/$botId/webchat/v2/share": {
      "filePath": "workspaces/$workspaceId/bots/$botId/webchat/v2/share.tsx",
      "parent": "/workspaces/$workspaceId/bots/$botId/webchat"
    },
    "/workspaces/$workspaceId/bots/$botId/webchat/v2/theme": {
      "filePath": "workspaces/$workspaceId/bots/$botId/webchat/v2/theme.tsx",
      "parent": "/workspaces/$workspaceId/bots/$botId/webchat"
    }
  }
}
ROUTE_MANIFEST_END */
