import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { isForbiddenError, isHttpUnauthorizedError } from '../client'
import { logout } from '../services'
import { config } from '../shared'
import { ReactQueryMeta } from '~/@types/react-query'
import { isApiError } from 'botpress-client'
import { captureException } from '@sentry/react'
import { showErrorToast } from '@bpinternal/ui-kit'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      gcTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      if (isApiError(error) && error.code > 500) {
        captureException(error, {
          tags: { pathname: window.location.pathname, queryKey: query.queryHash },
        })
      }

      return onError(
        error,
        query.meta ?? {
          suppressError: false,
          forbiddenBehavior: 'no-op',
        }
      )
    },
  }),
  mutationCache: new MutationCache({
    onError: (error, _, __, mutation) => onError(error, mutation.meta ?? { suppressError: false }),
  }),
})

type Props = {
  children: React.ReactNode
}

export const ReactQueryProvider = ({ children }: Props) => (
  <QueryClientProvider client={queryClient}>
    {children}
    <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-right" />
  </QueryClientProvider>
)

function onError(apiError: Error, meta: ReactQueryMeta) {
  if (!meta?.suppressError) {
    showErrorToast((meta.errorMessage as string) ?? apiError?.message ?? 'An invalid error value was encountered')
  }

  if (isHttpUnauthorizedError(apiError) && !config.usePat) {
    logout()
  }

  if (isForbiddenError(apiError) && meta.forbiddenBehavior === 'redirect') {
    window.location.href = '/forbidden'
  }
}
