'use client'

import {
  Boxes,
  ChartPie,
  ChartSpline,
  ChevronsUpDown,
  CreditCard,
  FileText,
  Headset,
  House,
  LayoutPanelLeft,
  MessageSquare,
  MessageSquareReply,
  MessagesSquare,
  Plus,
  Radio,
  Settings,
  SlidersHorizontal,
  SquareTerminal,
  Table,
  TriangleAlert,
  UsersRound,
  Variable,
  type LucideIcon,
} from 'lucide-react'
import * as React from 'react'

import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from '@bpinternal/ui-kit-next'
import { useParams, type LinkOptions, type RegisteredRouter, type ValidateLinkOptions } from '@tanstack/react-router'
import type { Workspace } from 'botpress-client'
import { createBotPrompt } from '../../features/bots/services/createBotPrompt'
import { WorkspaceProfilePicture } from '../../features/workspaces/components'
import { useCreateBot, useCurrentRouteId } from '../../hooks'
import { useQuery } from '../../services'
import { useAppStore, useLocalStore } from '../../stores'
import { cn } from '../../utils'
import { BotIcon } from '../BotIcon'
import { WorkspacesDropdown } from './WorkspacesDropdown'
import { NavGroup } from './NavGroup'
import { NavUser } from './NavUser'

import { INTERCOM_ELEMENT_ID } from '../../providers/IntercomProvider'
import { BotsDropdown } from './BotsDropdown'
import type { ReactNode } from 'react'
import { Boundary } from '../Boundary'
import { NotificationBadge } from '../../features/issues/componentsV2'
import { AppearanceDropdown } from './AppearanceDropdown'

export type NavItem = {
  title: string
  linkOptions: LinkOptions
  icon?: LucideIcon
  isActive?: boolean
  content?: ReactNode
}

const CreateWorkspaceNavItems: ({ workspaceId, routeId }: { workspaceId: string; routeId?: string }) => NavItem[] = ({
  routeId,
  ...routeParams
}) => {
  const params = routeParams as any
  return [
    {
      title: 'Home',
      icon: House,
      linkOptions: { params, to: '/workspaces/$workspaceId/home' },
    },
    {
      title: 'Your Integrations',
      icon: Boxes,
      linkOptions: { params, to: '/workspaces/$workspaceId/integrations' },
    },
    { title: 'Usage', icon: ChartPie, linkOptions: { params, to: '/workspaces/$workspaceId/usage' } },
    { title: 'Billing', icon: CreditCard, linkOptions: { params, to: '/workspaces/$workspaceId/billing' } },
    {
      title: 'Settings',
      icon: Settings,
      linkOptions: { params, to: '/workspaces/$workspaceId/settings/details' },
    },
  ].map((item) => {
    return { ...item, isActive: item?.linkOptions.to && routeId?.includes(item.linkOptions.to) } as NavItem
  })
}

const CreateBotNavItems: ({
  botId,
  workspaceId,
  routeId,
}: {
  botId: string
  workspaceId: string
  routeId?: string
}) => NavItem[] = ({ routeId, ...routeParams }) => {
  const params = routeParams as any
  return [
    {
      title: 'Overview',
      icon: LayoutPanelLeft,
      linkOptions: { to: '/workspaces/$workspaceId/bots/$botId/overview', params },
    },
    {
      title: 'Webchat',
      icon: MessageSquare,
      linkOptions: { params, to: '/workspaces/$workspaceId/bots/$botId/webchat' },
    },
    {
      title: 'Logs',
      icon: SquareTerminal,
      linkOptions: { params, to: '/workspaces/$workspaceId/bots/$botId/logs' },
    },
    {
      title: 'Conversations',
      icon: MessagesSquare,
      linkOptions: { params, to: '/workspaces/$workspaceId/bots/$botId/conversations' },
    },
    {
      title: 'Users',
      icon: UsersRound,
      linkOptions: { params, to: '/workspaces/$workspaceId/bots/$botId/users' },
    },
    {
      title: 'Files',
      icon: FileText,
      linkOptions: { params, to: '/workspaces/$workspaceId/bots/$botId/files' },
    },
    {
      title: 'Analytics',
      icon: ChartSpline,
      linkOptions: { params, to: '/workspaces/$workspaceId/bots/$botId/analytics' },
    },
    {
      title: 'Events',
      icon: Radio,
      linkOptions: { params, to: '/workspaces/$workspaceId/bots/$botId/events' },
    },
    {
      title: 'Tables',
      icon: Table,
      linkOptions: { params, to: '/workspaces/$workspaceId/bots/$botId/tables' },
    },

    {
      title: 'Issues',
      icon: TriangleAlert,
      content: (
        <Boundary loader={null} onError={() => null}>
          <NotificationBadge botId={params.botId} workspaceId={params.workspaceId} />
        </Boundary>
      ),

      linkOptions: { params, to: '/workspaces/$workspaceId/bots/$botId/issues' },
    },
    {
      title: 'Configuration variables',
      icon: Variable,
      linkOptions: { params, to: '/workspaces/$workspaceId/bots/$botId/configuration-variables' },
    },
    {
      title: 'Human in the loop',
      icon: MessageSquareReply,
      linkOptions: { params, to: '/workspaces/$workspaceId/bots/$botId/hitl' },
    },
  ].map((item) => {
    return { ...item, isActive: item?.linkOptions.to && routeId?.includes(item.linkOptions.to) } as NavItem
  })
}

type SidebarProps = React.ComponentProps<typeof Sidebar> & { workspaces: Workspace[] }
export function AppSidebar({ workspaces, ...props }: SidebarProps) {
  const activeWorkspace = useAppStore((s) => s.activeWorkspace)
  const routeId = useCurrentRouteId()

  const { workspaceId, botId } = useParams({ strict: false })
  const lastBotId = useLocalStore((state) => state.getLastActiveBotId(workspaceId))
  const navBotId = botId ?? lastBotId

  const { data: bots, isFetching: isFetchingBots } = useQuery('workspaces_/$workspaceId_/bots_', {
    workspaceId: workspaceId ?? '',
  })
  const { mutate: createBot, isPending } = useCreateBot(workspaceId ?? '')

  const { data: bot, isFetching: isFetchingBot } = useQuery('workspaces_/$workspaceId_/bots_/$botId_', {
    workspaceId: workspaceId ?? '',
    botId: navBotId ?? '',
  })

  const hasBots = (bots?.length ?? 0) > 0
  const isFetching = isFetchingBots || isFetchingBot

  return (
    <Sidebar collapsible="icon" variant="inset" {...props}>
      {activeWorkspace && (
        <SidebarHeader>
          <SidebarMenu>
            <SidebarMenuItem>
              <WorkspacesDropdown workspaces={workspaces}>
                <SidebarMenuButton size="lg">
                  <WorkspaceProfilePicture
                    workspaceId={activeWorkspace.id}
                    className="size-8 flex-none rounded-md"
                    pictureUrl={activeWorkspace.profilePicture}
                  />
                  <div className="grid flex-1 text-left text-sm leading-tight">
                    <span className="truncate font-semibold">{activeWorkspace.name}</span>
                    <span className="truncate text-xs capitalize">{activeWorkspace.plan}</span>
                  </div>
                </SidebarMenuButton>
              </WorkspacesDropdown>
            </SidebarMenuItem>
          </SidebarMenu>
        </SidebarHeader>
      )}
      <SidebarContent>
        <NavGroup title={'Workspace'} items={CreateWorkspaceNavItems({ workspaceId: workspaceId ?? '', routeId })} />
        <NavGroup items={bot ? CreateBotNavItems({ botId: bot.id, workspaceId: workspaceId ?? '', routeId }) : []}>
          {!isFetching &&
            (hasBots ? (
              <BotsDropdown bots={bots ?? []}>
                <SidebarMenuButton className={cn(bot ?? 'justify-between')} size={'sm'} tooltip="Select a bot">
                  {bot ? (
                    <>
                      <BotIcon id={bot.id} size="1" />
                      <span className="truncate">{bot.name}</span>
                      <ChevronsUpDown className="ml-auto" />
                    </>
                  ) : (
                    <>
                      <span className="truncate group-data-[collapsible=icon]:hidden">Select a bot...</span>
                      <ChevronsUpDown />
                    </>
                  )}
                </SidebarMenuButton>
              </BotsDropdown>
            ) : (
              <SidebarMenuButton
                disabled={isPending}
                size={'sm'}
                onClick={() => void createBotPrompt().then(createBot)}
                tooltip={'Create a bot'}
              >
                <Plus />
                <span>Create bot</span>
              </SidebarMenuButton>
            ))}
        </NavGroup>

        <SidebarGroup className="mt-auto">
          <SidebarGroupContent>
            <SidebarMenu>
              <SidebarMenuItem>
                <SidebarMenuButton size="sm" id={INTERCOM_ELEMENT_ID}>
                  <Headset />
                  <span>Help desk</span>
                </SidebarMenuButton>
              </SidebarMenuItem>
              <SidebarMenuItem>
                <AppearanceDropdown>
                  <SidebarMenuButton size={'sm'}>
                    <SlidersHorizontal />
                    <span>Appearance</span>
                  </SidebarMenuButton>
                </AppearanceDropdown>
              </SidebarMenuItem>
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
      </SidebarContent>

      <SidebarFooter>
        <NavUser workspaces={workspaces} />
      </SidebarFooter>
    </Sidebar>
  )
}
