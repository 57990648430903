import { DialogFooter, showDialog } from '@bpinternal/ui-kit'
import { Flex, TextField, Text } from '@radix-ui/themes'
import { useRouter } from '@tanstack/react-router'
import type { Workspace } from 'botpress-client'
import { useState, type ReactNode } from 'react'
import { DYNAMIC_QUOTAS, QuotaUsedPercentageWarningThreshold } from '~/features/usage/constants'
import { WorkspaceProfilePicture } from '~/features/workspaces/components'
import { useCreateWorkspace, useWorkspacesUsages } from '~/hooks'
import { useAppStore } from '~/stores'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@bpinternal/ui-kit-next'
import { CircleAlert, Plus, TriangleAlert } from 'lucide-react'

type Props = { className?: string; workspaces: Workspace[]; children: ReactNode }

export const WorkspacesDropdown = ({ workspaces, children }: Props) => {
  const activeWorkspace = useAppStore((s) => s.activeWorkspace)
  const navigate = useRouter().navigate
  const workspacesUsages = useWorkspacesUsages({
    workspaceIds: workspaces.map((w) => w.id),
    quotas: DYNAMIC_QUOTAS,
  })
  const [open, setOpen] = useState(false) // Need to controle the state for now because of a bug in radix, see: https://github.com/radix-ui/primitives/issues/837#issuecomment-2716644471

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild>{children}</DropdownMenuTrigger>
      <DropdownMenuContent className="w-60">
        <DropdownMenuLabel>Workspaces</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {workspaces
          ?.sort((a, b) => a.name.localeCompare(b.name))
          .map((workspace) => {
            const workspaceUsage = workspacesUsages[workspace.id]
            const anyUsageHasReachedQuota = Object.entries(workspaceUsage ?? {}).some(
              ([, usage]) => usage.percentage >= 100
            )
            const anyUsageExceedsWarningThreshold = !anyUsageHasReachedQuota
              ? Object.entries(workspaceUsage ?? {}).some(
                  ([, usage]) => usage.percentage > QuotaUsedPercentageWarningThreshold
                )
              : true
            return (
              <DropdownMenuItem
                key={workspace.id}
                disabled={workspace.id === activeWorkspace?.id}
                onClick={() => navigate({ to: '/workspaces/$workspaceId', params: { workspaceId: workspace.id } })}
              >
                <WorkspaceProfilePicture
                  workspaceId={workspace.id}
                  className="h-5 w-5 rounded-full"
                  pictureUrl={workspace.profilePicture}
                />
                <span className="truncate">{workspace.name}</span>
                {anyUsageHasReachedQuota ? (
                  <CircleAlert className="ml-auto text-red-10" />
                ) : anyUsageExceedsWarningThreshold ? (
                  <TriangleAlert className="ml-auto text-amber-10" />
                ) : null}
              </DropdownMenuItem>
            )
          })}
        <DropdownMenuSeparator />
        <DropdownMenuItem
          onClick={() => {
            setOpen(false)
            showDialog(
              ({ close }) => (
                <NewWorkspaceModal
                  onWorkspaceCreated={(workspace) => {
                    void navigate({ to: '/workspaces/$workspaceId', params: { workspaceId: workspace.id } })
                    close()
                  }}
                />
              ),
              {
                title: 'Create a workspace',
              }
            )
          }}
        >
          <Plus />
          <span>Create a workspace</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

type NewWorkspaceModalProps = {
  onWorkspaceCreated: (workspace: Workspace) => void
}

const NewWorkspaceModal = ({ onWorkspaceCreated }: NewWorkspaceModalProps) => {
  const [workspaceName, setWorkspaceName] = useState('')
  const { mutate: createWorkspace, isPending } = useCreateWorkspace()

  return (
    <>
      <Flex direction={'column'} gap={'4'}>
        <Text size={'1'}>This is the name you’ll see in your workspace list.</Text>
        <TextField.Root
          value={workspaceName}
          onChange={(e) => {
            setWorkspaceName(e.target.value)
          }}
          placeholder="Workspace name"
        />
      </Flex>
      <DialogFooter
        loading={isPending}
        onConfirm={() => {
          createWorkspace({ name: workspaceName, onSuccess: onWorkspaceCreated })
        }}
      />
    </>
  )
}
