import { DialogFooter, showDialog, showErrorToast, showSuccessToast } from '@bpinternal/ui-kit'
import { Flex, Text, TextField } from '@radix-ui/themes'
import { HiOutlineFlag } from 'react-icons/hi2'
import { copyToClipboard } from '~/utils'
import { Avatar } from '../../elementsv2'
import { ProfilePictureField } from '../ProfilePictureField'
import { useUpdateUserProfile } from '../../hooks'
import { useState, type FC, type ReactNode } from 'react'
import { showBugReportDialog } from '../BugReportDialog'
import type { User } from '../../features/users/types'
import { config } from '../../shared'
import { useAppStore } from '../../stores'
import { useRouter } from '@tanstack/react-router'
import _ from 'lodash'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from '@bpinternal/ui-kit-next'
import { DropdownMenuTrigger } from '@radix-ui/react-dropdown-menu'
import { KeyRound, Link, Lock, LogOut, User as UserIcon } from 'lucide-react'

type Props = { className?: string; user: User; hasAccessToWorkspace: boolean; children: ReactNode; disabled?: boolean }

export const UserDropdown: FC<Props> = ({ user, hasAccessToWorkspace, children, disabled }) => {
  const activeWorkspace = useAppStore((s) => s.activeWorkspace)
  const navigate = useRouter().navigate
  const [open, setOpen] = useState(false) // Need to controle the state for now because of a bug in radix, see: https://github.com/radix-ui/primitives/issues/837#issuecomment-2716644471

  return (
    <DropdownMenu
      open={open}
      onOpenChange={(isOpen) => {
        setOpen(isOpen)
      }}
    >
      <DropdownMenuTrigger disabled={disabled}>{children}</DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel>Profile</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          onClick={() => {
            setOpen(false)
            showDialog((props) => <UserProfileModal {...props} user={user} />, {
              title: 'Update Profile',
            })
          }}
        >
          <UserIcon />
          <span>Update Profile</span>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => location.assign(config.ssoBaseUrl.concat('/link'))}>
          <Link />
          <span>Link social accounts</span>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => location.assign(config.ssoBaseUrl.concat('/settings'))}>
          <KeyRound />
          <span>Change password</span>
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => {
            if (!hasAccessToWorkspace || !activeWorkspace?.id) {
              return
            }
            void navigate({
              to: '/workspaces/$workspaceId/profile/settings',
              params: { workspaceId: activeWorkspace.id },
            })
          }}
          disabled={!hasAccessToWorkspace}
        >
          <Lock />
          <span>Personal access tokens</span>
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={(e) => {
            setOpen(false)
            showBugReportDialog({
              onSubmit: ({ id }) => void copyToClipboard(id, 'issue ID', { event: e, preventDefault: true }),
            })
          }}
        >
          <HiOutlineFlag />
          <span>Report a bug</span>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={() => location.replace(config.ssoBaseUrl.concat('/logout'))}>
          <LogOut />
          <span>Sign out</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

const UserProfileModal = ({ user, close }: { user: User; close: () => void }) => {
  const [profile, setProfile] = useState(_.pick(user, 'displayName', 'profilePicture'))

  const updateProfile = useUpdateUserProfile({
    onSuccess: () => {
      showSuccessToast('Profile updated')
    },
    onError: () => {
      showErrorToast('Error updating profile')
    },
  })

  return (
    <>
      <Flex direction={'column'} gap={'3'} pb={'2'}>
        <ProfilePictureField
          pictureUrl={profile.profilePicture}
          onChange={(pictureUrl) => {
            setProfile({ ...profile, profilePicture: pictureUrl })
          }}
        >
          <Avatar name={user.email} pictureUrl={profile.profilePicture} />
        </ProfilePictureField>
        <Flex direction={'column'}>
          <Text size={'2'} className="text-gray-10">
            E-mail
          </Text>
          <Text size={'2'} className="hover:cursor-not-allowed">
            {user.email}
          </Text>
        </Flex>
        <div>
          <Text size={'2'} className="text-gray-10">
            Full Name
          </Text>
          <TextField.Root
            value={profile.displayName ?? ''}
            onChange={(e) => {
              setProfile({ ...profile, displayName: e.target.value })
            }}
            placeholder="John Doe"
          />
        </div>
      </Flex>
      <DialogFooter
        onConfirm={() => {
          if (!profile.displayName || (profile.displayName.length ?? 0) > 100) {
            return showErrorToast('Invalid Name')
          }
          void updateProfile.mutate(profile)
          close()
        }}
        onCancel={close}
      />
    </>
  )
}
