import { useSuspenseQuery, type QueryClient } from '@tanstack/react-query'
import { Outlet, useParams, ScrollRestoration, createRootRouteWithContext } from '@tanstack/react-router'
import { useAppStore, useLocalStore } from '../stores'
import { workspacesQueryOptions } from '../queries'
import { Box, Container } from '@radix-ui/themes'
import { useCurrentRouteId } from '../hooks/useCurrentRouteId'
import { useEffect } from 'react'
import { trackPage, usePortal } from '../providers'
import { DialogProvider } from '@bpinternal/ui-kit'
import { FileRouteTypes } from '~/routeTree.gen'
import { Separator, SidebarInset, SidebarProvider, SidebarTrigger } from '@bpinternal/ui-kit-next'
import { AppSidebar, NavbarBreadcrumbs } from '../componentsV2'

const FULL_SCREEN_ROUTES: FileRouteTypes['fullPaths'][] = ['/welcome']

type RouterContext = {
  queryClient: QueryClient
}

export const Route = createRootRouteWithContext<RouterContext>()({
  beforeLoad: async () => {
    const { currentUser: user } = useAppStore.getState()
    if (!user) {
      throw new Error('Unable to get current user')
    }
    return { user }
  },
  loader: ({ context }) => context.queryClient.ensureQueryData(workspacesQueryOptions()),
  component: RootComponent,
})

function RootComponent() {
  const { data: workspaces } = useSuspenseQuery(workspacesQueryOptions())
  const routeId = useCurrentRouteId()
  const expandedSidebar = useLocalStore((state) => state.expandedSidebar)
  const setExpandedSidebar = useLocalStore((state) => state.setExpandedSidebar)

  const { setBannerPortalRef } = usePortal()

  const { workspaceId, botId, conversationId, tableId } = useParams({ strict: false })

  useEffect(() => {
    if (routeId) {
      trackPage({ name: routeId, workspaceId, botId, conversationId })
    }
  }, [routeId])

  const isFullScreen = FULL_SCREEN_ROUTES.includes(routeId ?? '')

  return (
    <>
      <DialogProvider />
      {isFullScreen ? (
        <Box className="min-h-svh w-full">
          <Container>
            <Box className="has-[:not(.hidden)]:mb-4" ref={setBannerPortalRef} />
          </Container>
          <Outlet />
        </Box>
      ) : (
        <SidebarProvider open={expandedSidebar} onOpenChange={setExpandedSidebar}>
          <AppSidebar workspaces={workspaces} />
          <SidebarInset>
            <header className="flex h-16 shrink-0 items-center gap-2">
              <div className="flex items-center gap-2 px-4">
                <SidebarTrigger className="-ml-1" />
                <Separator orientation="vertical" className="mr-2 h-4" />
                <NavbarBreadcrumbs botId={botId} workspaceId={workspaceId} tableId={tableId} />
              </div>
            </header>
            <Box className="p-4 lg:px-8">
              <Container size={'4'}>
                <Box className="has-[:not(.hidden)]:mb-4" ref={setBannerPortalRef} />
              </Container>
              <Outlet />
            </Box>
          </SidebarInset>
        </SidebarProvider>
      )}
    </>
  )
}
