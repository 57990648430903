import { cn } from '~/utils'
import { Badge, Text } from '@radix-ui/themes'
import { useSuspenseQuery } from '@tanstack/react-query'
import { listAllIssuesQueryOptions } from '~/queries'
import { useParams } from '@tanstack/react-router'

type Props = { className?: string; botId?: string; workspaceId?: string }

export const NotificationBadge = ({ botId, workspaceId, ...props }: Props) => {
  const params = useParams({ strict: false })

  botId = botId || params.botId
  workspaceId = workspaceId || params.workspaceId

  if (!botId || !workspaceId) {
    return null
  }

  return <InnerBadge workspaceId={workspaceId} botId={botId} {...props} />
}

type InnerBadgeProps = { className?: string; workspaceId: string; botId: string }
const InnerBadge = ({ className, workspaceId, botId, ...props }: InnerBadgeProps) => {
  const { data: issues } = useSuspenseQuery(listAllIssuesQueryOptions({ botId, workspaceId }))

  return issues.length > 0 ? (
    <Badge size={'1'} color="red" variant="soft" {...props} className={cn('mt-0.5', className)}>
      <Text size={'1'}>{issues.length}</Text>
    </Badge>
  ) : null
}
