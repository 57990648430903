import { Outlet, createFileRoute } from '@tanstack/react-router'
import { useCurrentRouteId } from '../../../hooks'
import type { ComponentProps } from 'react'
import { Page } from '~/componentsV2'

export const Route = createFileRoute('/workspaces/$workspaceId/settings')({
  component: Component,
})

function Component() {
  const currentRouteId = useCurrentRouteId() ?? ''

  const items: ComponentProps<typeof Page>['navbarItems'] = [
    { children: 'Details', to: '/workspaces/$workspaceId/settings/details' },
    { children: 'Members', to: '/workspaces/$workspaceId/settings/members' },
    { children: 'Audits', to: '/workspaces/$workspaceId/settings/audits' },
  ]

  const itemsWithActiveState = items.map((i) => ({ ...i, active: currentRouteId.includes(i?.to ?? 'not-a-path') }))

  return (
    <Page title="Settings" navbarItems={itemsWithActiveState}>
      <Outlet />
    </Page>
  )
}
