import { Container, Flex, Separator, TabNav, Text } from '@radix-ui/themes'
import { ReactNode, forwardRef, type ForwardedRef, type ComponentProps } from 'react'
import { createLink } from '@tanstack/react-router'
import { usePortal } from '../providers'
import type { LinkOptions } from '../types/navigation'

type Props = {
  title?: ReactNode
  actions?: ReactNode
  justify?: 'start' | 'center' | 'end' | 'between'
  children?: ReactNode
  className?: string
  navbarItems?: NavbarProps['navbarItems']
}

export const Page = ({ title, actions, children, justify = 'between', className, navbarItems = [] }: Props) => {
  const { setPageActionsPortalRef } = usePortal()

  return (
    <Container size={'4'} className={className}>
      <Flex align={'center'} justify={justify} gap={'4'}>
        <Text size={'6'} weight={'bold'} className="p-0.5">
          {title}
        </Text>
        <Flex gap={'2'}>
          <Flex ref={setPageActionsPortalRef} gap={'2'} />
          {actions}
        </Flex>
      </Flex>
      {navbarItems.length > 0 ? (
        <Navbar navbarItems={navbarItems} mb={'4'} />
      ) : (
        <Separator size={'4'} mb={'4'} className="mt-1.5" />
      )}
      {children}
    </Container>
  )
}

type NavbarProps = {
  navbarItems: (Pick<ComponentProps<typeof TabNavLink>, 'children' | 'leading' | 'trailing' | 'active'> &
    Pick<LinkOptions, 'to' | 'from'>)[]
} & ComponentProps<typeof TabNav.Root>
const Navbar = ({ navbarItems, ...props }: NavbarProps) => {
  return (
    <TabNav.Root {...props}>
      {navbarItems.map((item) => {
        const { to, from, ...rest } = item
        return <TabNavLink key={to} to={to as any} from={from as any} {...rest} />
      })}
    </TabNav.Root>
  )
}

type TabNavLinkProps = ComponentProps<typeof TabNav.Link> & {
  leading?: ReactNode
  trailing?: ReactNode
}

export const TabNavLink = createLink(
  forwardRef(({ children, leading, trailing, ...props }: TabNavLinkProps, ref: ForwardedRef<HTMLAnchorElement>) => {
    return (
      <TabNav.Link {...props} ref={ref}>
        <Flex align={'center'} gap={'2'}>
          {leading}
          {children}
          {trailing}
        </Flex>
      </TabNav.Link>
    )
  })
)
