import { createFileRoute, useNavigate, redirect } from '@tanstack/react-router'
import { NotFound } from '~/components/NotFound'
import { publicWorkspaceQueryOptions, workspaceHandleAvailableQueryOptions, workspacesQueryOptions } from '~/queries'

export const Route = createFileRoute('/$')({
  beforeLoad: async ({ context, params }) => {
    const { _splat: maybeWorkspaceHandle } = params
    const { usedBy: workspaceId } = await context.queryClient.ensureQueryData(
      workspaceHandleAvailableQueryOptions(maybeWorkspaceHandle ?? '')
    )
    if (!workspaceId) {
      return
    }

    const workspaces = await context.queryClient.ensureQueryData(workspacesQueryOptions())

    const userIsMember = workspaces.some((w) => w.id === workspaceId)
    if (userIsMember) {
      throw redirect({
        to: `/workspaces/${workspaceId}/home` as any,
        mask: { to: `/${maybeWorkspaceHandle}` as any, unmaskOnReload: false },
        params: { workspaceId } as any,
      })
    }

    const pubWorkspace = await context.queryClient.ensureQueryData(publicWorkspaceQueryOptions(workspaceId))
    // The workspace is private or doesn't exist
    if (!pubWorkspace) {
      return
    }

    throw redirect({
      to: '/profile/$workspaceId',
      mask: { to: `/${maybeWorkspaceHandle}` as any, unmaskOnReload: false },
      params: { workspaceId },
    })
  },
  component: () => {
    const navigate = useNavigate()
    return (
      <NotFound
        navigateToHome={() => {
          void navigate({ to: '/' })
        }}
      />
    )
  },
})
