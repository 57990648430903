import cx from 'classnames'
import { generateGradient } from '~/layouts'
export type WorkspaceProfilePictureProps = {
  pictureUrl?: string
  workspaceId: string
  className?: string
}

const WorkspaceProfilePicture = ({ className, ...props }: WorkspaceProfilePictureProps) => {
  if (props.pictureUrl) {
    return (
      <img src={props.pictureUrl} alt="Workspace avatar" className={cx('border-1 border border-gray-3', className)} />
    )
  }

  return (
    <div
      className={cx(className, 'bg-gradient-to-b', { grayscale: false }, generateGradient(props.workspaceId ?? ''))}
    />
  )
}

export { WorkspaceProfilePicture }
